import { Button, Col, Row } from "antd";
import axios from "axios";
import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";

const CryptoList = () => {
  const [cryptoList, setCryptoList] = useState([]);
  const [displayCategory, setDisplayCategory] = useState("mostPopular");

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        const response = await axios.get(
          "https://api.binance.com/api/v3/ticker/24hr"
        );

        const data = response.data.map((crypto) => ({
          symbol: crypto.symbol,
          lastPrice: crypto.lastPrice,
          priceChangePercent: crypto.priceChangePercent,
          marketCap: parseFloat(crypto.marketCap || "0"),
        }));

        console.log(response.data);

        const usdtOnly = data.filter((crypto) =>
          crypto.symbol.endsWith("USDT")
        );

        const sortedByMarketCap = [...usdtOnly].sort(
          (a, b) => b.marketCap - a.marketCap
        );
        const sortedByPriceChange = [...usdtOnly].sort(
          (a, b) =>
            parseFloat(b.priceChangePercent) - parseFloat(a.priceChangePercent)
        );
        const sortedFallingLeaders = [...usdtOnly].sort(
          (a, b) =>
            parseFloat(a.priceChangePercent) - parseFloat(b.priceChangePercent)
        );

        let selectedCategory = [];
        if (displayCategory === "mostPopular") {
          selectedCategory = sortedByMarketCap.slice(0, 6);
        } else if (displayCategory === "growthLeaders") {
          selectedCategory = sortedByPriceChange.slice(0, 6);
        } else if (displayCategory === "fallingLeaders") {
          selectedCategory = sortedFallingLeaders.slice(0, 6);
        }

        setCryptoList(selectedCategory);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    fetchCryptoData();
  }, [displayCategory]);

  const fetchHistoricalData = async (symbol) => {
    try {
      const response = await axios.get(
        "https://api.binance.com/api/v3/klines",
        {
          params: {
            symbol: symbol,
            interval: "1M",
            limit: 12,
          },
        }
      );

      const historicalData = response.data.map((entry) => ({
        timestamp: entry[0],
        price: parseFloat(entry[4]),
      }));

      const labels = historicalData.map((entry) =>
        new Date(entry.timestamp).toLocaleDateString(undefined, {
          month: "short",
        })
      );

      const data = historicalData.map((entry) => entry.price);

      return { labels, data };
    } catch (error) {
      console.error("Error fetching historical data:", error);
      return { labels: [], data: [] };
    }
  };

  const CryptoChart = ({ symbol }) => {
    const [historicalData, setHistoricalData] = useState({
      labels: [],
      data: [],
    });

    useEffect(() => {
      const fetchAndSetData = async () => {
        const data = await fetchHistoricalData(symbol);
        setHistoricalData(data);
      };
      fetchAndSetData();
    }, [symbol]);

    const chartOptions = {
      chart: {
        id: "crypto-chart",
        type: "line",
        toolbar: {
          show: false,
        },
        stacked: false,
      },
      xaxis: {
        categories: historicalData.labels,
        tooltip: {
          enabled: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          formatter: (value) =>
            typeof value === "number" ? `${value} $` : value,
        },
      },
      colors: ["#a034fa", "#a034fa"],
      stroke: {
        curve: "smooth",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      grid: {
        show: true,
        borderColor: "rgba(255, 255, 255, 0.1)",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 20,
              color: "#572c7c",
              opacity: 0.4,
            },
            {
              offset: 50,
              color: "#572c7c",
              opacity: 0.3,
            },
            {
              offset: 100,
              color: "#a034fa",
              opacity: 0.3,
            },
          ],
        },
      },
    };

    const chartSeries = [
      {
        name: "Цена на этот период",
        data: historicalData.data,
        type: "area",
      },
    ];

    return (
      <div className="chart">
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={100}
        />
      </div>
    );
  };

  return (
    <section className="currency-chart">
      <div className="box v2">
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="tabs-chart">
              <Button
                className={displayCategory === "mostPopular" ? "active" : ""}
                onClick={() => setDisplayCategory("mostPopular")}
              >
                Активные
              </Button>
              <Button
                className={displayCategory === "growthLeaders" ? "active" : ""}
                onClick={() => setDisplayCategory("growthLeaders")}
              >
                Лидеры роста
              </Button>
              <Button
                className={displayCategory === "fallingLeaders" ? "active" : ""}
                onClick={() => setDisplayCategory("fallingLeaders")}
              >
                Лидеры падения
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="coins">
          {cryptoList.map((crypto) => (
            <Col xl={24} lg={24} md={24} sm={24} xs={24} key={crypto.symbol}>
              <div className="coin-item">
                <Row align="middle">
                  <Col xl={8} lg={8} md={7} sm={7} xs={7}>
                    <div className="title">{crypto.symbol}</div>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={9} xs={9}>
                    <span>$ {Number(crypto.lastPrice).toFixed(2)}</span>
                  </Col>
                  <Col xl={5} lg={5} md={5} sm={3} xs={3}>
                    <span
                      className={
                        parseFloat(crypto.priceChangePercent) >= 0
                          ? "green"
                          : "red"
                      }
                    >
                      {crypto.priceChangePercent}%
                    </span>
                  </Col>
                  <Col xl={4} lg={4} md={24} sm={24} xs={24}>
                    <CryptoChart symbol={crypto.symbol} />
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>

        <span class="screw screw-lines-1"></span>
        <span class="screw screw-lines-2"></span>
        <span class="screw screw-lines-3"></span>
        <span class="screw screw-lines-4"></span>
      </div>
    </section>
  );
};

export default CryptoList;
