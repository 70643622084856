import { Col, Row } from "antd";
import React from "react";
import { IconCertificate } from "../../utils/Icons";

const License = () => {
  return (
    <section className="license">
      <Row justify="center">
        <Col xl={20} lg={20} md={24} sm={24} xs={24}>
          <div className="box">
            <div className="icon orange">
              <IconCertificate width="24" height="24" fill="#fff" />
            </div>
            <h4>Company registration</h4>
            <Row>
              <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                <p>
                  Integrity, reliability, transparency, flexibility and
                  communication should be the core values of any company that
                  works in the field of investment attraction. Centure adheres
                  to absolutely all of these values so that our clients can have
                  confidence and trust in us.
                </p>
                <p>
                  License No. <b>8597366</b>
                </p>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <p>Legal address:</p>
                <p>
                  99 Chancery Street, <br />
                  Auckland Central,
                  <br /> Auckland, 1010, New Zealand
                </p>
              </Col>
            </Row>

            <span class="screw screw-lines-2"></span>
            <span class="screw screw-lines-3"></span>
            <span class="screw screw-lines-4"></span>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default License;
