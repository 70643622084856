import React from "react";

const IconShieldCheck = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.625 2.081C10.746 2.456 5.47199 4.70701 4.59399 5.082C4.22499 5.239 4 5.58701 4 5.98801C4 10.368 4.17499 12.328 4.96899 14.585C6.00799 17.54 8.048 19.895 11.5 21.868C11.807 22.044 12.193 22.044 12.5 21.868C15.983 19.877 18.032 17.53 19.062 14.585C19.846 12.344 20 10.48 20 5.98801C20 5.58901 19.773 5.24 19.406 5.082L12.438 2.081C12.186 1.973 11.877 1.973 11.625 2.081ZM12 4.04999L17.996 6.65302C18.083 13.828 16.677 16.973 12.009 19.834C7.22 16.855 5.94801 13.676 5.99701 6.66101C7.36001 6.07901 10.847 4.54199 12 4.04999Z"
        fill={fill}
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L12.7071 13.7071C12.3166 14.0976 11.6834 14.0976 11.2929 13.7071L9.29289 11.7071C8.90237 11.3166 8.90237 10.6834 9.29289 10.2929C9.68342 9.90237 10.3166 9.90237 10.7071 10.2929L12 11.5858L14.2929 9.29289Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconShieldCheck;
