import React from "react";

const IconClipboardData = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.17449 6H6C3.79086 6 2 7.79086 2 10V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V10C22 7.79086 20.2091 6 18 6H16.8378C16.943 6.31013 17 6.64601 17 7C17 7.35399 16.943 7.68987 16.8378 8H18C19.1046 8 20 8.89543 20 10V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V10C4 8.89543 4.89543 8 6 8H7.17089C7.06019 7.68697 7 7.35032 7 7C7 6.6489 7.06181 6.31241 7.17449 6Z"
        fill={fill}
        fill-opacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 5C11 4.45994 11.4399 4 12 4C12.5848 4 13 4.40889 13 5C13 5.55228 13.4477 6 14 6C14.5802 6 15 6.41061 15 7C15 7.58939 14.5802 8 14 8H10C9.44679 8 9 7.55116 9 7C9 6.4611 9.47243 6 10 6C10.5523 6 11 5.55228 11 5ZM12 2C10.6148 2 9.49696 2.92287 9.12902 4.13293C7.91888 4.50984 7 5.64628 7 7C7 8.65191 8.33841 10 10 10H14C15.6671 10 17 8.7116 17 7C17 5.60236 16.1112 4.48691 14.8775 4.12471C14.5132 2.8695 13.3758 2 12 2ZM16 11C16.5523 11 17 11.4477 17 12V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V12C15 11.4477 15.4477 11 16 11ZM13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V14ZM9 16C9 15.4477 8.55228 15 8 15C7.44772 15 7 15.4477 7 16V17C7 17.5523 7.44772 18 8 18C8.55228 18 9 17.5523 9 17V16Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconClipboardData;
