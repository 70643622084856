import React from "react";

const IconCpu = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 8C4 5.79086 5.79086 4 8 4H16C18.2091 4 20 5.79086 20 8V16C20 18.2091 18.2091 20 16 20H8C5.79086 20 4 18.2091 4 16V8ZM8 6C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V8C18 6.89543 17.1046 6 16 6H8Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 3C9 2.44772 8.55228 2 8 2C7.44772 2 7 2.44772 7 3V4.12602C7.31962 4.04375 7.6547 4 8 4H9V3ZM3 7H4.12602C4.04375 7.31962 4 7.6547 4 8V9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7ZM3 11H4V13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11ZM3 15H4V16C4 16.3453 4.04375 16.6804 4.12602 17H3C2.44772 17 2 16.5523 2 16C2 15.4477 2.44772 15 3 15ZM7 21V19.874C7.31962 19.9562 7.6547 20 8 20H9V21C9 21.5523 8.55228 22 8 22C7.44772 22 7 21.5523 7 21ZM11 21V20H13V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21ZM15 21V20H16C16.3453 20 16.6804 19.9562 17 19.874V21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21ZM21 17H19.874C19.9562 16.6804 20 16.3453 20 16V15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17ZM21 13H20V11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13ZM21 9H20V8C20 7.6547 19.9562 7.31962 19.874 7H21C21.5523 7 22 7.44772 22 8C22 8.55228 21.5523 9 21 9ZM17 3V4.12602C16.6804 4.04375 16.3453 4 16 4H15V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3ZM13 3V4H11V3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3ZM9.5 8C8.67157 8 8 8.67157 8 9.5V14.5C8 15.3284 8.67157 16 9.5 16H14.5C15.3284 16 16 15.3284 16 14.5V9.5C16 8.67157 15.3284 8 14.5 8H9.5ZM10 14V10H14V14H10Z"
        fill={fill}
        fill-opacity="0.4"
      />
    </svg>
  );
};

export default IconCpu;
