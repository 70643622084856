export const TRANSLATIONS_RU = {
  navBar: {
    links: {
      item1: "Безопасность",
      item2: "Терминал",
      item3: "О нас",
      item4: "Контакты",
      item5: {
        title: "Наши партнеры",
        list1: "Поставщики ликвидности",
        list2: "Лицензии",
      },
    },

    lang: {
      "en-US": "English",
      "ru-RU": "Русский",
    },

    buttons: {
      login: "Вход",
      register: "Регистрация",
    },
  },

  home: {
    banner: {
      title: "Ваш партнер в финансовом мире",
      desc: "Узнайте о наших целях и преимуществах, делающих нас лучшими в этой отрасли.",
      btn: "Стать инвестором",
    },

    CTA: {
      title: "Время действовать!",
      desc: "Предоставляем уникальную возможность раскрыть ваш инвестиционный потенциал и заработать.",
      btn: "Стать инвестором",
    },

    counter: {
      title: "Наши показатели",
      lists: {
        item1: "Ежедневный оборот",
        item2: "Общий клиентский баланс",
        item3: "Активные трейдеры",
        item4: "Лицензии",
        item5: "Поставщики ликвидности",
      },
    },

    awards: {
      title: "Наши награды и достижения",
      lists: {
        item1: {
          title: "Ведущий профессиональный счет",
          desc: "Business Tabloid Awards",
        },
        item2: {
          title: "Самый стремительно развивающийся брокер",
          desc: "Global 100 Awards",
        },
        item3: {
          title: "Активное развитие",
          desc: "M&A Today",
        },
        item4: {
          title: "Самый порядочный брокер",
          desc: "Global Brands Magazine",
        },
        item5: {
          title: "Самый надежный торговый терминал",
          desc: "IAFT Awards",
        },
        item6: {
          title: "Наилучший профессиональный счет",
          desc: "Blue Ribbon Winner",
        },
      },
    },

    steps: {
      title: "Начать торговать - просто",
      step: "Шаг",
      item1: "Зарегистрируйтесь",
      item2: "Пополните счет",
      item3: "Торгуйте",
    },

    accounts: {
      title: "Аккаунты: типы и особенности",
      btn: "Стать инвестором",

      lists: {
        item1: {
          title: "Elementary",
          desc: " Этот тип аккаунта обычно предоставляет ограниченный доступ к функциям и возможностям платформы. Он часто используется для ознакомления с сервисом или базовой работы.",
        },

        item2: {
          title: "Medium",
          desc: "Лучшее предложение! Предоставляет более широкие функциональные возможности по сравнению с начальным уровнем. Включает в себя дополнительные функции и больший объем преимуществ.",
        },

        item3: {
          title: "Professional",
          desc: "Профессиональный аккаунт предоставляет наибольший доступ и расширенный набор функций. Включает в себя дополнительный перечень аналитических инструментов, персональную поддержку и т.д.",
        },

        option: {
          deposit: "Минимальный депозит:",
          shoulder: "Кредитное плечо:",
          items: {
            option1: "Мгновенное исполнение сделок",
            option2: "Торговля криптовалютой",
            option3: "Открытие личного ПАММ-счета",
            option4: "Доступ к ETF и индексам",
            option5: "Персональный менеджер",
            option6: "Страхование депозитов",
            option7: "Отсутствие свопа",
          },
        },
      },
    },
  },

  about: {
    banner: {
      title: "Ваш партнер в финансовом мире",
      desc: "Узнайте о наших целях и преимуществах, делающих нас лучшими в этой отрасли.",
      btn: "Стать инвестором",
    },

    licenses: {
      title: "Лицензии",
      btn: "Подробнее",
    },

    advantages: {
      title: "Преимущества платформы",
      lists: {
        item1: {
          title:
            "Мы предоставляем индивидуальный подход, помогая вам достичь ваших финансовых целей, будь то сбережения, инвестиции или обеспечение финансовой стабильности.",
          desc: "Это ценность ваших финансовых средств",
        },
        item2: {
          title:
            "У нас есть множество инструментов и рынков, на которых вы можете торговать. Независимо от того, интересуетесь ли вы акциями, криптовалютами, валютами или сырьем, мы предоставляем доступ к разнообразным рынкам.",
          desc: "Огромное количество торговых возможностей",
        },
        item3: {
          title:
            "Мы разработали платформу, которая облегчает торговлю даже для начинающих инвесторов. Наш пользовательский интерфейс интуитивно понятен, а аналитические инструменты предоставляют всю необходимую информацию для принятия обоснованных инвестиционных решений.",
          desc: "Наша доступная платформа",
        },
      },
    },

    history: {
      title: "История нашей компании",

      lists: {
        item1: {
          title: "Превозносимое начало",
          desc: "В эти годы мы стартовали с амбициозными мечтами и преданным коллективом. Началось это путешествие, которое навсегда изменило нас, и укрепило наши корни в индустрии.",
        },
        item2: {
          title: "Рост и опыт",
          desc: "Этот период был отмечен стремительным ростом. Мы усилили наши сервисы, привлекли новых клиентов и приобрели ценный опыт в управлении финансами и инвестициями.",
        },
        item3: {
          title: "Инновации и развитие",
          desc: "В эти годы мы продолжили инновационные изменения и эксперименты. Наша платформа стала более совершенной, а наши услуги - еще более удовлетворительными.",
        },
        item4: {
          title: "Будущее на горизонте",
          desc: "Наш взгляд устремлен в будущее, с бесконечными возможностями. Мы продолжаем стремиться к улучшению и оказанию поддержки нашим клиентам, даря им финансовую стабильность и уверенность.",
        },
      },
    },
  },

  platform: {
    banner: {
      title: "Терминал",
      desc: "Наши инновационные торговые решения позволяют вам контролировать и улучшать вашу стратегию на финансовых рынках. Узнайте больше о нашей платформе и начните эффективную торговлю уже сегодня.",
      btn: "Стать инвестором",
    },

    steps: {
      lists: {
        item1: {
          title: "Аналитические инструменты",
          desc: "Наш торговый терминал предоставляет доступ к передовым аналитическим инструментам, помогая вам принимать обоснованные решения.",
        },
        item2: {
          title: "Безопасность и надежность",
          desc: "Мы обеспечиваем высший уровень безопасности ваших финансовых данных и сделок, чтобы вы могли торговать с уверенностью.",
        },
        item3: {
          title: "Пользовательская настройка",
          desc: "Наша платформа адаптируется к вашим потребностям, позволяя настраивать интерфейс и инструменты согласно вашим требованиям.",
        },
      },
    },

    orders: {
      title: "Типы ордеров на рынках",

      lists: {
        item1: {
          title: "Рыночный ордер",
          desc: "Исполняется немедленно по текущей рыночной цене. Это означает, что вы готовы купить или продать по самой выгодной на данный момент цене на рынке.",
        },
        item2: {
          title: "Лимитный ордер",
          desc: "Лимитный ордер позволяет вам указать конкретную цену, по которой вы хотите купить или продать. Ордер будет ожидать, пока рыночная цена достигнет указанной вами цены, и после этого он будет автоматически исполнен.",
        },
        item3: {
          title: "Стоп-ордер",
          desc: "Это ордер, который активируется при достижении рыночной ценой определенного уровня, называемого стоп-ценой. Когда стоп-цена достигнута, ордер становится рыночным ордером и исполняется по текущей цене.",
        },
        item4: {
          title: "Стоп-лосс ордер",
          desc: "Он устанавливается на определенном уровне цены, и если рыночная цена достигает этого уровня, ордер автоматически активируется, помогая защитить инвестора от дополнительных потерь.",
        },
        item5: {
          title: "Тейк-профит ордер",
          desc: "Тейк-профит ордер позволяет автоматически закрыть позицию, когда цена достигнет заданного уровня прибыли. Вы указываете цель прибыли, и когда рыночная цена достигает этой цели, ордер автоматически исполняется.",
        },
        item6: {
          title: "Трейлинг-стоп ордер",
          desc: "Трейлинг-стоп ордер подстраивается под движение цены. Вы указываете начальный стоп-уровень, и когда цена идет в вашу пользу, стоп-уровень автоматически поднимается, сохраняя заданное расстояние от текущей цены.",
        },
      },
    },

    description: {
      lists: {
        item1: {
          title: "Торгуй с графиков",
          subtitle: "Особенная функциональная возможность",
          desc: "Торговый клиентский терминал обеспечивает обширные возможности для выполнения торговых операций непосредственно с графика финансового инструмента. В сочетании с выбранной вами стратегией это позволяет быстро открывать, редактировать и закрывать позиции, а также управлять отложенными ордерами.",
        },
        item2: {
          title: "API-ключ",
          subtitle: "Получай доступ к новым функциям",
          desc: "На нашей платформе реализован исключительный функционал, являющийся нашей отличительной чертой - API-ключ. В действительности, этот инструмент служит для получения первоклассной поддержки, улучшения вашего опыта взаимодействия с терминалом, а также для доступа к наилучшим функциональным возможностям.",
        },
        item3: {
          title: "Управляй своим аккаунтом",
          subtitle: "Ключ к финансовой уверенности",
          desc: "Управление счетом представляет собой стратегию финансового управления, которая имеет множество преимуществ: эффективное финансовое планирование, снижение финансовых рисков, увеличение сбережений и тд. Управление счетом требует дисциплины и самодисциплины, но в итоге оно приносит множество финансовых преимуществ.",
        },
      },
    },
  },

  security: {
    banner: {
      title: "Безопасность",
      desc: "Ваша безопасность — наш приоритет. Мы работаем, чтобы вы могли спокойно использовать нашу платформу, зная, что вы в надежных руках.",
      btn: "Стать инвестором",
    },

    description: {
      title: "Стандарты надежности и безопасности",

      lists: {
        item1: {
          title: "Современные технологии шифрования",
          desc: "Мы используем передовые технологии шифрования для защиты ваших данных и транзакций, обеспечивая высший уровень конфиденциальности.",
        },
        item2: {
          title: "Двухфакторная аутентификация",
          desc: "Для повышения безопасности вашего аккаунта мы предлагаем двухфакторную аутентификацию, которая требует дополнительного подтверждения при входе и совершении транзакций.",
        },
        item3: {
          title: "Регулярные аудиты и мониторинг",
          desc: "Мы регулярно проводим аудиты и мониторинг наших систем для выявления и предотвращения любых потенциальных угроз безопасности.",
        },
        item4: {
          title: "Разделение средств клиентов",
          desc: "Средства наших клиентов хранятся на отдельных счетах и строго разделяются от корпоративных средств, обеспечивая их доступность и безопасность.",
        },
      },
    },

    collaterals: {
      title: "Обеспечение",
      desc: "Мы способны выполнить свои финансовые обязательства в срок и полностью покрываем ваши депозиты своими активами.",
    },
  },

  licenses: {
    banner: {
      title: "Лицензии",
      desc: "Мы зарекомендовали себя в отрасли как компания, имеющая огромное количество связей с различными международными финансовыми институтами. Мы гордимся своей безупречной репутацией, а наши операции строго контролируются различными финансовыми регуляторами, обеспечивая вашу безопасность и доверие.",
      btn: "Стать инвестором",
    },

    lists: {
      item1:
        "Это финансовый регулятор в Дубае. DFSA регулирует широкий спектр финансовых институтов, включая банки, страховые компании, инвестиционные фонды и др. Также стремится обеспечить стабильность и прозрачность финансовых операций.",
      item2:
        'Это аббревиатура, которая означает "Финансовый мониторинг и контроль". Это важный финансовый регулятор в Великобритании, ответственный за надзор и регулирование финансовых рынков и финансовых компаний в стране.',
      item3:
        "Это Кипрская комиссия по ценным бумагам и биржам. Это официальный регулятор финансовых рынков на острове Кипр и один из ключевых органов в области финансового регулирования в Европейском союзе.",
      item4:
        "Это регулирующий орган, который ответственен за контроль и надзор за финансовыми рынками в Южной Африке. Он был создан для обеспечения прозрачности и честности в финансовой индустрии и защиты интересов инвесторов.",
    },
  },

  regulators: {
    banner: {
      title: "Поставщики ликвидности",
      desc: "Мы гордимся тем, что наши партнеры являются ведущими поставщиками ликвидности на рынке. Богатое портфолио, опыт и их передовые технологии позволяют обеспечивать нашим клиентам надежный и высококачественный доступ к финансовым ресурсам, что помогает им добиваться успешных результатов в сфере инвестиций и торговли.",
      btn: "Стать инвестором",
    },

    tabs: {
      item1:
        "Предоставляет широкий спектр финансовых услуг, включая банковские услуги для корпораций и частных лиц, инвестиционное банковское обслуживание, управление активами, страхование и многое другое.",
      item2:
        "Это крупнейший американский мировой банк и финансовая корпорация. Компания широко известна своей ролью в инвестиционном банкинге, управлении активами, торговле ценными бумагами и других финансовых услугах.",
      item3:
        "Это американская финансовая компания, которая предоставляет широкий спектр услуг в области инвестиций и финансового консультирования. Компания стала одной из крупнейших финансовых организаций в Соединенных Штатах.",
      item4:
        "Это финансовая корпорация с внушительным портфелем финансовых услуг. Компания стала одним из глобальных лидеров в области инвестиционного банкинга, управления активами и других финансовых услуг.",
      item5:
        "Предоставляет разнообразные банковские услуги, охватывая все сферы банковской деятельности. Это включает розничное банковское обслуживание для частных клиентов и малых предприятий, а также обслуживание для крупных компаний.",
      item6:
        "Предоставляет широкий спектр финансовых услуг, включая банковские счета, кредитные карты, ипотечные кредиты, инвестиционное банковское обслуживание и управление активами. Банк имеет множество филиалов и банкоматов.",
      item7:
        "Имеет множество клиентов по всему миру и предоставляет услуги как частным лицам, так и корпоративным клиентам. Банк также активен в области социальной ответственности, поддерживая различные благотворительные программы.",
      item8:
        "Является одним из крупнейших финансовых институтов в мире и играет ключевую роль в международной системе финансов. Занимает место среди ключевых поставщиков ликвидности, обеспечивая стабильность и функционирование многих финансовых рынков.",
    },
  },

  contacts: {
    banner: {
      title: "Наши контакты",
      desc: "Свяжитесь с нами для уточнения информации по волнующим вас вопросам.",
      btn: "Стать инвестором",
    },

    info: {
      title: "Контактная информация",
      email: "Почта",
      address: "Адрес",
    },

    form: {
      title: "Задайте нам вопрос",
      desc: "Свяжитесь с нами для уточнения информации по волнующим вас вопросам.",
      lastName: "Ваше имя *",
      firstName: "Ваша фамилия *",
      phone: "Ваш телефон *",
      email: "Ваш E-mail *",
      mass: "Сообщение",
      btn: "Отправить",
      required: "обязательное поле",
    },
  },

  documents: {
    links: {
      item1: "Пользовательское соглашение",
      item2: "Политика вывода и возврата средств",
      item3: "Заявление о раскрытии рисков",
      item4: "Противодействие отмыванию денег",
      item5: "Политика в отношении конфликта интересов",
    },
  },

  footer: {
    markets: {
      title: "Рынки",
      lists: {
        item1: "Сырьевые товары",
        item2: "Металлы",
        item3: "Индексы",
        item4: "Акции",
        item5: "Валюты",
        item6: "Криптовалюты",
      },
    },

    sections: {
      title: "Разделы",
      lists: {
        item1: "Безопасность",
        item2: "Терминал",
        item3: "О нас",
        item4: "Новости",
        item5: "Контакты",
      },
    },

    partners: {
      title: "Наши партнеры",
      lists: {
        item1: "Поставщики ликвидности",
        item2: "Лицензии",
      },
    },

    desc: {
      address: "Юридический адрес: Viktualienmarkt 8, 80331 München, Germany",
      copy: "Защищено SSL. © 2024 BHP Trade. Авторское право. Все права защищены.",
      title: "BHP Trade - компания, оказывающая финансовые услуги.",
      info1:
        "Управление компанией регламентировано и надзор за деятельностью осуществляется Управлением по финансовому регулированию и надзору Дубаи (Dubai Financial Services Authority, DFSA) – номер лицензии инвестиционного дилера: K542681. А также в соответствии с Кипрской комиссией по ценным бумагам и биржам (Cyprus Securities and Exchange Commission, CySEC) – номер лицензии: CIF 547/84.",
      info2:
        "Отказ от ответственности: продукты и услуги с контрактами на разницу («CFD») не предназначены для использования резидентами любых стран или юрисдикций, где такое распространение или использование противоречит местному законодательству.",
    },
  },

  agreement: {
    title: "1. Термины и определения  ",
    lists: {
      item1:
        "1.1 В настоящем документе, являющимся Клиентским Соглашением (далее – Соглашение), использованы следующие определения и термины: ",
      item2:
        "“Клиент” –  любое физическое лицо или любое юридическое лицо, которое заполнило Форму регистрации Клиента, кроме тех физических лиц, которые не достигли восемнадцатилетнего возраста и которые не имеют гражданства.  ",
      item3: "“Компания” BHP Trade ",
      item4:
        "“Услуги” – услуги Компании, которые она оказывает Клиенту и которые указаны в Пункте 4 Соглашения.  ",
      item5:
        "“Веб-сайт» – веб-сайт Компании в сети Интернет, а также любой другой веб-сайт, признаваемый Компания официальным и периодически поддерживаемый ей для доступа Клиентов.   ",
      item6:
        "“Торговая платформа” – все программы или технические средства Компании, состоящие из Сервера и Клиентского терминала, которые проводят Торговые операции, позволяют получать Котировки в режиме реального времени, позволяют выставлять/ изменять/ удалять / оформлять Ордера и производить расчет всех взаимных обязательств между Клиентом и Компанией.  ",
      item7:
        "“Сервер” – программный продукт, посредством которого Компания обрабатывает клиентские Запросы и распоряжения, предоставляет Клиенту информацию о торгах на финансовых рынках в режиме реального времени и в определенном Компанией объеме, ведет учет взаимных обязательств с Клиентом в соответствии с условиями Нормативно-правовых документов.  ",
      item8:
        '“Форма регистрации клиента” – форма заявления, которую Клиент должен заполнить для регистрации себя как Клиента в сервисе "Личный кабинет" и которая доступна на Веб-сайте Компании. ',
      item9:
        "“Данные доступа” – коды авторизации и доступа Клиента, пароль и номер Торгового счета Клиента, любая информация, необходимая Компании для выставления Ордеров Клиенту.  ",
      item10:
        "“Данные Клиента” – любые документы или информация, которые были получены Компанией от самого Клиента или относительно Клиента, его Счета, предоставления ему или использования им Услуг.  ",
      item11:
        "“Дата предоставления данных” – дата предоставления данных, оговоренная сторонами. ",
      item12:
        "“Спецификация контракта” – основные условия торговли, которые применимы к каждому отображаемому на Веб-сайте Компании Инструменту: Размер лота, Спред, Первоначальная маржа, Хеджированная маржа. ",
      item13:
        "“Торговая операция” – любая сделка, которая заключается Клиентом или от имени Клиента в соответствии с Соглашением и Регламентами осуществления операций.   ",
      item14:
        "“Объем Торговой операции” – вычисляется по формуле: размер лота х число лотов.  ",
      item15:
        "“Торговый счет” – уникальная персонифицированная система, в которой происходит регистрация всех Полных законченных транзакций, Открытых позиций, Ордеров и сделок по депонированию или снятию средств в Торговой платформе.  ",
      item16:
        "“Средства” – текущее состояние счета Клиента, определяемое по формуле: Баланс + Плавающая прибыль – Плавающий убыток.  ",
      item17:
        "“Валюта Торгового счета” – та валюта, которую выбрал Клиент при открытии Торгового счета. ",
      item18:
        "“Базовая валюта” – первая в Валютной паре валюта, по курсу обмена которой Клиент покупает или продает котируемую Валюту.  ",
      item19:
        "“Валюта котировки” – вторая в Валютной паре валюта, которую Клиент покупает или продает для того, чтобы получить Базовую валюту.  ",
      item20:
        "“Валютная пара” – объект Торговой операции, основанный на изменении стоимости одной валюты по отношению к другой.  ",
      item21:
        "“Баланс” – финансовый итог всех Завершенных сделок и операций по денежным переводам, которые были осуществлены по Торговому счету.  ",
      item22:
        "“Длинная позиция” – покупка Инструмента с расчетом на повышение курса или, применительно к валютным парам, покупка за Валюту котировки Базовой валюты.  ",
      item23:
        "“Короткая позиция” – прибыльная при падении рыночных цен сделка по продаже валюты или применительно к Валютным парам курс продажи Базовой валюты относительно Валюты котировки. ",
      item24:
        "“Котировка” – информация о текущей цене на определенный Инструмент в форме Цен Ask или Bid.  ",
      item25:
        "“Индикативная котировка” – Котировка, по которой Компания вправе не принимать любые инструкции или не выполнять какие-либо Ордера. ",
      item26:
        "“База котировок” – информация о Потоке котировок, которая хранится на Сервере Компании.  ",
      item27:
        "“Поток котировок” – процесс поступления Котировок по Инструментам в Торговую платформу.  ",
      item28:
        "“Запрос” или “Запрос котировки” – инструкция Клиента в адрес Компании на получение котировки, не являющаяся обязательством совершить сделку.  ",
      item29:
        "“Ошибочные котировки” – котировки, которые поступили в Клиентский терминал из-за системной технической ошибки.  ",
      item30: "“Курс” – означает:",
      item31:
        "1) Применительно к Драгоценному металлу цена за одну тройскую унцию Драгоценного металла в валюте, предусмотренной для данного Инструмента в Спецификации контракта; или ",
      item32:
        "2) Применительно к Валютной паре стоимость единицы Базовой валюты в Валюте котировки. ",
      item33:
        "“Инструмент” – Валютная пара, Контракт на разницу, Металлы спот и любые другие финансовые инструменты Компании, которые она предлагает Клиенту.  ",
      item34:
        "“Кредитное плечо” или “Соотношение между заемными и собственными средствами” – соотношение 1:25, 1:50, 1:100, 1:200, 1:500, 1:1000 Объема Торговой операции и Первоначальной маржи. Соотношение 1:100 применительно к открытию позиции означает, что размер Первоначальной маржи в сто раз меньше Объема Торговой операции. На Веб-сайте Компании могут быть опубликованы и другие соотношения. ",
      item35:
        "“Маржа” или “Залог” – денежное обеспечение, требуемое Компанией для поддержания Открытых позиций. Указаны в Спецификации контракта по каждому Инструменту.",
      item36:
        "“Маржинальная торговля” – срочные сделки, в которых Клиент может торговать Инструментами, имея существенно меньше средств на Торговом счете, чем необходимо для Объема Торговой операции.  ",
      item37:
        "“Неправомерный доход” – доход, полученный в результате: нарушения какого-либо или нескольких положений Нормативно-правовых документов, Ошибочной котировки или наступления Случая неисполнения обязательств. ",
      item38:
        '"“Необходимая маржа” – требуемое Компанией денежное обеспечение для поддержания открытых позиций, указанное для каждого Инструмента на Веб-сайте Компании в Спецификации контрактов.  ',
      item39:
        "“Оговоренная процедура” – любая процедура, оговоренная Сторонами, относящаяся к Спорной ситуации, кроме такой Процедуры урегулирования Спорной ситуации, в которую Стороны имеют право вносить изменения. ",
      item40:
        "“Ордер” – распоряжение Клиента открыть или закрыть позицию по достижении ценой уровня ордера.  ",
      item41:
        "“Stop Loss” – Торговый ордер на закрытие открытой ранее позиции по менее выгодной для Клиента цене, чем текущая цена в момент размещения Ордера.",
      item42:
        "“Stop Out” – распоряжение на принудительное закрытие позиции, которое Сервер генерирует в случае нехватки средств для поддержания Открытой позиции. Закрытие позиции производится без необходимости получения согласия от Клиента и без его Предварительного уведомления.  ",
      item43:
        "“Take Profit” – Торговый ордер на закрытие ранее открытой позиции по более выгодной для Клиента цене, чем текущая цена в момент размещения Ордера.  ",
      item44:
        "“Открытая позиция” – Короткая или Длинная позиция, которая не обозначается как Полная законченная транзакция.  ",
      item45:
        "“Уровень маржи” – процентное соотношение Необходимой маржой и Средств, которое рассчитывается по формуле: (Средства / Необходимая маржа) × 100%.  ",
      item46: "“Уровень ордера” – цена, которая указана в Ордере.  ",
      item47:
        "“Драгоценный металл” или “Металл спот” – наличное серебро или наличное золото.  ",
      item48:
        "“Лот” – стандартная сумма Сделки в Торговой платформе, выраженная в величине объема Ценных бумаг, Базовой валюты, тройских унций Драгоценного металла.  ",
      item49:
        "“Размер лота” – указанное в Спецификации контракта количество Ценных бумаг, единиц Базовой валюты, базовых активов или тройских унций Драгоценного металла в одном Лоте.  ",
      item50:
        "“Аффилированное лицо” – любое юридическое лицо, которое прямо или косвенно контролирует Компанию, либо само контролируемо ей, либо находится под общим контролем с Компанией. Под “контролем” здесь следует понимать владение контрольным пакетом акций Компании или упомянутых выше аффилированных лиц.  ",
      item51:
        "“Лог-файл клиентского терминала” – файл, который с точностью до секунды протоколирует все запросы и распоряжения, отправленные Клиентом Компании. Создается клиентским терминалом. ",
      item52:
        "“Лог-файл сервера” – файл, который с точностью до секунды протоколирует все запросы и распоряжения, поступившие от Клиента Компании, а также результаты их обработки. Создается сервером.  ",
      item53:
        "“Локированные позиции” – одинакового объема Короткие и Длинные позиции, открытые Компанией по одному и тому же Инструменту на одном Торговом счете, включая (но не ограничиваясь ими) любые торговые и (или) неторговые операции.  ",
      item54:
        "“Нормальные рыночные условия” – рынок без быстрых движений цены, существенных разрывов в Потоке котировок на Торговой платформе, больших Ценовых разрывов.  ",
      item55: "“Первоначальная маржа” – маржа, требуемая для открытия позиции.",
      item56:
        "“Свободная маржа” – такие Средства на Торговом счете, которые могут быть использованы для открытия позиции. Свободная маржа исчисляется по формуле: Средства – Необходимая маржа.  ",
      item57:
        "“Хеджированная маржа” – требуемая Компанией и описанная в Спецификации контрактов маржа, достаточная для открытия и поддержания Позиций с противоположной направленностью.  ",
      item58:
        "“Цена спроса” или “Ask” – более высокая цена Котировки, по которой Клиент может покупать валюту. ",
      item59:
        "“Цена предложения” или “Bid” – более низкая цена Котировки, по которой Клиент может продавать валюту. ",
      item60: "“Спред” – разница между Ask и Bid.  ",
      item61:
        "“Ценовой разрыв» – Ситуация, когда Текущая Цена Ask ниже, чем Цена Bid по предыдущей Котировке или когда Текущая Цена Bid выше, чем Цена Ask по предыдущей Котировке. ",
      item62:
        "“Плавающая прибыль”, “Плавающий убыток” – текущие прибыли или убытки по Открытым позициям, исчисляемые по текущим Котировкам.  ",
      item63:
        "“Поставщик ликвидности” – банк или ECN, который предоставляет потоковые цены Компании и может использоваться ей для хеджирования клиентских сделок. ",
      item64:
        "“Сторонний поставщик услуг” – оговоренное Сторонами юридическое лицо, которое будет обязано выполнять операции по соответствующей позиции для обеих Сторон.  ",
      item65:
        "“Рыночные условия, отличные от нормальных” – ценовые разрывы, низкая ликвидность, быстрое изменение цен на рынке и другие условия, отличающиеся от Обычных условий рынка.  ",
      item66:
        "“Форс-мажорные обстоятельства” – перечислены в Пункте 16 Соглашения.  ",
      item67: "2. Общие положения ",
      item68: "2.1 Соглашение заключено между Клиентом и Компанией.  ",
      item69: "2.2 Под Компанией следует понимать BHP Trade . ",
      item70:
        "2.3 Термины, Определения, используемые в Соглашении, указаны в Пункте 1 (далее “Термины и определения”).  ",
      item71:
        "2.4 Соглашение вместе с Нормативно-правовыми документами представляет собой полный объем договоренностей Клиента и Компании. К Нормативно-правовым документам относятся: Регламенты осуществления операций, Регламент о торговых операциях, Регламент предоставления услуги “ПАММ-счет”, Заявление о раскрытии рисков, а также любые другие документы из раздела “Договоры и регламенты», находящемся на Веб-сайте Компании, в которые могут периодически вноситься дополнения или изменения (далее совместно именуемые “Нормативно-правовые документы”). ",
      item72:
        "2.5 Нормативно-правовые документы призваны регулировать порядок осуществления всех операций Клиента в Компании, как торговых, так и неторговых, и должны быть внимательно изучены Клиентом, поскольку содержат важную информацию, которую Компания обязана предоставить Клиенту в соответствии с применимыми положениями закона.  ",
      item73:
        "2.6 В Нормативно-правовых документах, с принятием во внимание периодически вносимых изменений и дополнений, изложены условия, на которых Компания вправе осуществлять с Клиентом сделки в отношении Инструментов.  ",
      item74:
        "2.7 Применительно к каждой Торговой операции Клиента Компания действует не как его агент, а как непосредственный участник договора. Следовательно, если не оговорено иное, Компания будет считать Клиента клиентом во всех отношениях, а Клиент будет нести полную ответственность за исполнение обязательств по любой осуществляемой им Торговой операции. ",
      item75:
        "2.8 Если Клиент действует от имени или в отношении другого лица, то независимо от того, раскрывает Клиент его личность Компании или нет, Компания не будет считать вышеуказанное лицо косвенным клиентом и не будет нести какие-либо обязательства перед ним (если не оговорено иное).  ",
      item76: "3. Вступление в силу  ",
      item77:
        "3.1 Данное Соглашение связано с отдельными Торговыми операциями или серией следующих друг за другом Торговых операций, включая операции с Инструментами, и считается первоначальным соглашением об оказании услуг. ",
      item78:
        "3.2 Условия Соглашения и Нормативно-правовых документов признаются безоговорочно принятыми Клиентом в тот календарный день, когда Клиент получит уведомление в соответствии с Пунктом 4.1 и действуют до тех пор, пока не будут расторгнуты по инициативе любой из сторон.  ",
      item79:
        "3.3 Соглашение вступает в силу дистанционно, будучи заключенным в электронном виде. Клиент подтверждает, что он не вправе аннулировать Соглашение лишь на том основании, что оно является заключаемым дистанционно в электронном виде. ",
      item80: "4. Услуги  ",
      item81:
        "4.1 При условии исполнения Клиентом всех обязательств, возникающих в соответствии с Нормативно-правовыми документами и с учетом любых других предусмотренных положениями Нормативно-правовых документов прав Компании, Компания предоставляет Клиенту перечисленные ниже Услуги. ",
      item82:
        "4.1.1 Инвестиционные услуги, в том числе получение и передача заявок, выполнение за свой счет Ордеров Клиента касательно финансовых инструментов.  ",
      item83:
        "4.1.2 Услуги с иностранной валютой, которые имеют отношение к оказанию Инвестиционных услуг согласно Пункту 4.1.1 Соглашения.  ",
      item84:
        "4.1.3 Ответственное хранение финансовых инструментов и контроль за ними за счет Клиента (если применимо), включая сопряженные услуги, например, услуги по управлению денежным обеспечением, описанные в Пункте 8 Соглашения;  ",
      item85:
        "4.1.4 Предоставление Клиенту доступа к любой информации об анализе его капиталовложений. ",
      item86:
        "4.1.5 Страхование депозитов: счет клиента должен быть застрахован от полной или частичной потери средств (если сумма запроса на вывод средств с открытыми сделками на счету клиента подвергает риску других пользователей при использовании маржинального плеча, если компания оповестила об изменении регламента страхования по почте клиента), по требованию регулирующего органа лицензиата компании. ",
      item87:
        "4.1.6 Услуги по конвертации средств при их выводе (оказание за счет Клиента). ",
      item88:
        "4.1.7 Услуги по легализации полученной Клиентом прибыли в виде предоставления помощи в открытии счета в зарубежном банке и регистрации офшорной компании для легализации доходов. ",
      item89:
        "4.2 В случае исполнения Клиентом всех обязательств, перечисленных в Соглашении и Нормативно-правовых документах, Компания предоставит Клиенту возможность совершать предусмотренные этими документами операции с использованием Инструментов Веб-сайта и возможностей Личного кабинета.  ",
      item90:
        "4.3 Компания осуществляет все Торговые операции с Клиентом по принципу “только исполнение». Компания вправе исполнять вышеуказанные Торговые операции, несмотря на то, что какая-либо Торговая операция может быть невыгодной для Клиента.  ",
      item91:
        "4.4 Компания вправе предоставлять Клиенту, а Клиент вправе просить у Компании персональные рекомендации и инвестиционные консультации в отношении любых Торговых операций или мнений, которые могли бы мотивировать Клиента к осуществлению какой-либо Торговой операции.  ",
      item92:
        "4.5 Компания вправе периодически по своему усмотрению предоставлять рекомендации и иную информацию в новостях, которые она может публиковать на своем Веб-сайте, а также в виде рассылок подписчикам через свой Веб-сайт или иным образом. Любые рекомендации, аналитические отчеты и другая информация могут быть опубликованы Компанией на одном или нескольких интерфейсах информационных служб. В этом случае действуют перечисленные ниже правила.  ",
      item93:
        "4.5.1 Вышеуказанная информация не является инвестиционной рекомендацией или продвижением невостребованного Клиентом финансового продукта и предоставляется исключительно для того, чтобы позволить Клиенту принять собственные инвестиционные решения. ",
      item94:
        "4.5.2 Компания не предоставляет Клиенту никаких заверений или гарантий относительно налоговых последствий любой Торговой операции, а также полноты и точности вышеуказанной информации.  ",
      item95:
        "4.5.3 Компания не делает какие-либо заявления относительно срока получения информации Клиентом. В этой связи Компания не гарантирует, что Клиент получит вышеуказанную информацию в то же время, что и другие ее клиенты. ",
      item96:
        "4.5.4 Любая информация, предоставляемая Компанией на веб-сайте, может быть изменена или удалена в любое время без уведомления, включая рыночные комментарии, новости.  ",
      item97:
        "4.5.5 Если в каком-либо документе Компании содержится какое-либо ограничение относительно лица или категории лиц, для которых этот документ не предназначен, Клиент обязуется не передавать этот документ любому вышеуказанному лицу или категории лиц.  ",
      item98:
        "4.5.6 Информация не обязательно учитывает соответствующие законодательные или нормативно-правовые положения страны, резидентом которой является Клиент. Из этого следует, что Клиент обязан самостоятельно соблюдать вышеуказанные положения. ",
      item99:
        "4.6 Компания не обязана (если отсутствуют иные указания в Нормативно-правовых документах) проводить мониторинг или уведомлять Клиента о статусе любой Торговой операции Клиента, о закрытии любых Открытых позиций, о предъявлении маржинальных требований. ",
      item100:
        "4.7 Компания не обязана обеспечивать физическую доставку Базового актива какого-либо Инструмента в отношении любой Торговой операции. Прибыли или убытки от курсовых разниц будут отражены сразу после закрытия Торговой операции на Торговом счете.  ",
      item101:
        "4.8 Клиент соглашается, что Компания имеет право запросить, а Клиент обязан предоставить информацию о своих знаниях и опыте в области инвестиций. Клиент также подтверждает, что данная информация будет предоставлена таким образом, чтобы Компания могла оценить, подходит ли Клиенту рассматриваемая услуга или продукт. Если Клиент не предоставит Компании вышеуказанную информацию в достаточном объёме, Компания не сможет определить, подходит ли Клиенту рассматриваемая услуга или продукт. Компания будет считать, что предоставленная Клиентом вышеуказанная информация является точной и не будет нести ответственность перед Клиентом в случае неполноты, недостоверности, изменения информации (кроме случаев, когда Клиент уведомляет Компанию об этих изменениях) или в том случае, если она вводит в заблуждение.  ",
      item102:
        "4.9 Компания имеет право по своему усмотрению в любое время и без информирования о причинах отказать Клиенту в предоставлении Услуг. В случае наступления Рыночных условий, отличных от нормальных, Компания вправе приостанавливать, отсрочивать, изменять условия оказания своих услуг. ",
      item103:
        "4.10 Компания вправе закрыть, приостановить или аннулировать любую Торговую операцию, возникшую в результате какой-либо неверной конфигурации или технической ошибки Сервера, а также если она подозревает о любой мошеннической деятельности по счету (счетам) Клиента. К такой деятельности могут относиться (но не ограничиваться ими): арбитражные сделках купли-продажи, манипулятивные действия или другие формы вводящей в заблуждение деятельности, мошеннической деятельности, иным образом касающейся или связанной с любыми или всеми Торговыми операциями Клиента. В этом случае Компания будет вправе вычитать любые неправомерно полученные по ее мнению доходы и начислять связанные с ними издержки. Также в этом случае Компания не будет нести ответственность за аннулирование любой Торговой операции или доходов Клиента, не будет нести ответственность в случае любого ущерба или убытков, которые могут возникнуть в результате закрытия, приостановки или аннулирования Торговой операции.  ",
      item104:
        "4.11 Все Запросы от Клиента рассматриваются с точки зрения их размера. Ордер может быть исполнен частично либо может быть отклонен весь по единоличному усмотрению Компании, если размер Запроса от Клиента больше, чем Компания может исполнить в определенный момент в соответствии с текущими условиями рынка.  ",
      item105:
        "4.12 Клиент обязуется предоставить по требованию Компании персональные данные и документы о себе как о Клиенте: полное имя, дату и место рождения, адрес, юрисдикцию места проживания / регистрацию, номер банковского счета. Клиент соглашается передать вышеуказанную информацию Компании, зарегистрированной в качестве контроллера данных согласно Закону, необходимую в целях идентификации, в административных и деловых целях для исполнения своих правовых и договорных обязательств в соответствии с настоящим и другими соглашениями сторон. Указанная выше информация предоставляется Компании с правом на её передачу юристам, аудиторам, финансовым консультантам и другим поставщикам услуг и контрагентам, с которыми Компания заключает договоры.  ",
      item106:
        "4.13 Клиент подтверждает, что сохраненная в его Личном кабинете торговая и неторговая история будет доступна для просмотра и скачивания в любое время.  ",
      item107:
        "4.14 В случае если клиент стал субъектом финансового мониторинга (блокировка кошелька по сети) средства по счету клиента проходят процесс снятия через банки респонденты обслуживающие криптографические смарт-контракты конвертации средств клиента в национальную валюту клиента после предоставления данных банка получателя компании.  ",
      item108: "5. Активация Личного кабинета и Торгового счета  ",
      item109:
        "5.1 Компания активирует Личный кабинет Клиента, о чем уведомит его, после того, как получит от Клиента заполненную Форму регистрации Клиента, проведет соответствующие проверки идентификационных документов в соответствии с требованиями Компании, утвердит способы пополнения и вывода средств Клиентом, а также после того, как Клиент примет условия Нормативно-правовых документов.  ",
      item110:
        "5.2 Компания вправе по своему усмотрению отказать Клиенту в регистрации Личного кабинета на основании полученной от Клиента документации, даже заполненной Клиентом надлежащим образом и в полной мере. ",
      item111:
        "5.3 Компания вправе требовать от Клиента внесения им минимального первоначального взноса на свой Торговый счет для того, чтобы Клиент смог начать его использовать. ",
      item112:
        "5.4 После активации Личного кабинета и Торгового счета Клиент сможет в любое время видеть причитающуюся ему сумму в виде баланса счета в Личном кабинете и будет вправе по запросу снять ее. ",
      item113: "6. Обмен информацией  ",
      item114:
        "6.1 Правила обмена информацией между Компанией и Клиентом изложены в Регламентах торговых операций, которые размещены на Веб-сайте Компании в разделе “Договоры и регламенты”. ",
      item115:
        "6.2 Клиент обязуется предоставлять Распоряжения и Запросы исключительно посредством Клиентского терминала в соответствии с вышеуказанными Регламентами торговых операций. ",
      item116: "7. Ограничение ответственности и возмещение убытков  ",
      item117:
        "7.1 Компания не несет ответственность перед Клиентом за пользу любой предоставленной информации, консультации или рекомендации. Клиент признает, что Компания не будет нести ответственность в размере любых убытков, расходов, затрат или ущерба, понесенных Клиентом в результате какой-либо неточности или ошибки в любой предоставленной Клиенту информации, включая ту, которая связана с любыми Торговыми операциями, кроме ситуации, когда были проявлены злонамеренные действия, мошенничество или грубая неосторожность со стороны Компании. С учетом права Компании на закрытие или аннулирование любой Торговой операции в определенных указанных в Нормативно-правовых документах обстоятельствах, после вышеуказанной ошибки или неточности эта Торговая операция, тем не менее, остается действительной и налагает обязательства во всех отношениях как на Клиента, так и на Компанию. ",
      item118:
        "7.2 Компания не будет нести ответственность за любые убытки или расходы, понесенные Клиентом, и прямо или косвенно возникающие в связи с: действиями, упущениями или неосторожностью любого третьего лица; любым нарушением обязательств Компании, указанных в Нормативно-правовых документах, произошедшим по причинам, независящим от Компании; проводимыми через Клиентский терминал Торговыми операциями; любым сбоем в работе Торговой платформы, или с любой ошибкой в работе Торговой платформы, или с любой задержкой, вызванной Клиентским терминалом. ",
      item119:
        "7.3 По требованию Компании Клиент обязуется возместить Компании расходы любого рода (включая затраты, обязательства, исковые требования), которые Компания принимает на себя или несет как прямой или косвенный результат любого неисполнения Клиентом каких-либо обязательств, принятых им в соответствии с Нормативно-правовыми документами.  ",
      item120:
        "7.4 Если иное не оговорено в Регламентах торговых операций, Компания ни при каких обстоятельствах не будет нести перед Клиентом ответственность за любые прямые или косвенные убытки, упущенные прибыль или выгоду (в том числе относительно последовательных движений на рынке), за затраты, расходы или ущерб, которые Клиент может понести в соответствии с Нормативно-правовыми документами.  ",
      item121: "8. Комиссии и иные издержки  ",
      item122:
        "8.1 Клиент обязан выплачивать Компании комиссии и нести иные издержки, которые указаны в Спецификации контрактов, находящейся на Веб-сайте Компании в разделе “Торговые условия”. ",
      item123:
        "8.2 Компания обязуется публиковать все действующие комиссии и иные издержки на своем Веб-сайте и вправе вносить изменения в эту информацию без предоставления Клиенту предварительного Письменного уведомления. Сам факт публикации вышеуказанных изменений в комиссиях и иных издержках на Веб-сайте Компании будет считаться надлежащим уведомлением о данных изменениях.  ",
      item124:
        "8.3 Компания имеет вправо уведомлять Клиента, а Клиент соглашается получать вышеуказанные уведомления при выплате каких-либо комиссий / сборов любому третьему лицу, которое представило Клиента Компании или которое действует от его имени.  ",
      item125:
        "8.4 Клиент обязуется оплачивать все гербовые сборы, необходимые для осуществления Торговых операций, связанные с Соглашением и с Нормативно-правовыми документами.  ",
      item126:
        "8.5 Клиент лично несет ответственность за всю подаваемую отчетность и налоговые декларации по любым Торговым операциям, которые должны предоставляться в любой соответствующий орган государственной или иной власти и за саму оплату всех налогов, которыми облагается любая Торговая операция, включая без ограничения налог на передачу ценных бумаг или собственности или налог на добавленную стоимость. ",
      item127:
        "8.6 Все средства Клиента хранятся на счетах Компании, в том числе на сегрегированных счетах, открытых на имя Компании для хранения Клиентских средств отдельно от ее средств.  ",
      item128:
        "8.7 Компания не взимает комиссию за открытие счета и содержание счета при открытии. ",
      item129:
        "8.8 Компания не взымает комиссию за пополнение и снятие средств, комиссия может сниматься со стороны платежных систем которые используются клиентом. ",
      item130: "9. Использование Торговой платформы и безопасность  ",
      item131:
        "9.1 Клиент во время использования Торговой платформы не вправе допускать каких-либо действий или упущений, результатом которых может стать нарушение безопасности платформы или ее ненадлежащая работа. Клиент не вправе допускать каких-либо действий или упущений, которые потенциально могут привести к несоответствующему использованию Торговой платформы или к несанкционированному доступу к ней. Клиент признает, что Компания вправе по своему усмотрению прекратить или ограничить право доступа в Торговую платформу, если заподозрит Клиента в том, что он допустил несоответствующее ее использование или приступил к осуществлению вышеуказанных действий.  ",
      item132:
        "9.2 Клиент вправе хранить, анализировать, модифицировать, отображать, форматировать и распечатывать информацию, предоставляемую на Торговой платформе Компании. Однако Клиент не вправе публиковать, воспроизводить или иным образом полностью или частично передавать информацию, предоставляемую на Торговой платформе Компании, любому третьему лицу в каком-либо формате без согласия на то Компании. Клиент не вправе изменять, удалять или скрывать любые указания на торговые марки, а также на авторские или любые другие права, представленные на Торговой платформе Компании. ",
      item133:
        "9.3 Клиент обязуется хранить в секрете и не раскрывать любые Данные доступа к Торговой платформе Компании любому лицу. В случае, если Клиент заподозрит или ему станет известно о том, что его Данные доступа были или могли быть раскрыты любому не уполномоченному на то лицу, Клиент обязуется немедленно уведомить об этому Компанию. ",
      item134:
        "9.4 Клиент признает, что Компания не будет нести ответственность в том случае, если какие-либо неуполномоченные на то лица получат доступ к информации (включая персональные данные, электронные адреса, электронную переписку), когда эта информация передается через сеть Интернет или другие сетевые коммуникационные средства, а также по почте, по телефону или с использованием других электронных средств.  ",
      item135:
        "9.5 Клиент несет ответственность за все распоряжения или инструкции, передаваемые и предоставляемые на основании его Данных доступа. Любые вышеуказанные распоряжения или инструкции, полученные Компанией, будут считаться полученными ей от Клиента.   ",
      item136:
        "9.6 Клиент обязуется оказывать Компании содействие в любой следственной деятельности, которые Компания может осуществлять в связи с попыткой несанкционированного доступа и ненадлежащего использования его Данных доступа.  ",
      item137:
        "9.7 Если Компания подозревает о совершении мошенничества, манипуляционных действий, своп-арбитража или другой формы вводящей в заблуждение деятельности по счету (счетам) Клиента в Компании или несанкционированной деятельности, иным образом связанной с любыми Торговыми операциями, то она вправе: ",
      item138: "1) закрыть все открытые на Торговом счете Клиента позиции;  ",
      item139:
        "3) аннулировать все доходы, полученные в результате вышеуказанных действий;  ",
      item140: "10. Внесение изменений и расторжение Соглашения  ",
      item141:
        "10.1 Компанией вправе по своему усмотрению в одностороннем порядке и в любое время вносить изменения в Нормативно-правовые документы. В этом случае Компания обязана оповестить Клиента о вышеуказанных изменениях, опубликовав информацию о них на своем Веб-сайте или предоставив Клиенту уведомление по электронной почте.  ",
      item142:
        "10.2 Обе стороны вправе расторгнуть Соглашение, предоставив друг другу Письменные уведомления об этом.  ",
      item143:
        "10.3 По расторжению Соглашения Компания имеет право прекратить предоставлять Клиенту доступ к Торговой платформе без его предварительного уведомления об этом.  ",
      item144:
        "10.4 При расторжении Соглашения все суммы, которые Клиент обязан выплачивать Компании, будут подлежать немедленной оплате, включая (но не ограничиваясь):  ",
      item145: "1) все подлежащие оплате комиссии и сборы;  ",
      item146:
        "2) любые операционные расходы, понесенные Компанией в связи с расторжением Соглашения, а также расходы, понесенные ей при переводе инвестиций Клиента в другую инвестиционную компанию;  ",
      item147:
        "3) любые убытки и расходы, понесённые Компанией при закрытии любых Торговых операций Клиента и при урегулировании или исполнении любых просроченных обязательств, принятых Компанией от имени Клиента.",
      item148:
        "10.5 Компания вправе по своему усмотрению деактивировать счет Клиента без его предварительного уведомления, если Клиент размещает такое число ошибочных запросов, которое отклоняется от стандартного и которые создают чрезмерную нагрузку на Серверы Компании, вследствии чего они могут привести к отрицательному торговому опыту других клиентов, использующих эти Серверы.  ",
      item149:
        "К ошибочным запросам могут относиться, не ограничиваясь ими, большое число или превышающий допустимые значения объем запросов или заявок с недостаточными средствами на счете Клиента, неверная обработка Торговых операций или системного журнала, а также любые не имеющие силы шаги или изменения.  ",
      item150: "11. Конфликт интересов и материальная заинтересованность  ",
      item151:
        "11.1 В тех случаях, когда Компания осуществляет сделки с Клиентом или от имени Клиента, Компания, связанное лицо или любое другое аффилированное с Компанией лицо может иметь заинтересованность или договоренности, которые могут быть существенными по отношению к рассматриваемой Торговой операции или которые могут противоречить интересам Клиента. Ниже приведены примеры действий Компании в тех случаях, когда она осуществляет какие-либо сделки с Клиентом или от имени Клиента. ",
      item152:
        "11.1.1 Компания может осуществлять сделки с соответствующим Инструментом в качестве принципала за свой счет посредством покупки Инструмента у Клиента или продажи Инструмента Клиенту.  ",
      item153:
        "11.1.2 Компания может осуществлять сделки с Инструментом, который она предлагает Клиенту, включая удерживание Короткой или Длинной позиции.  ",
      item154:
        "11.1.3 Компания может согласовывать Торговые операции с Клиентом или с другим клиентом, действуя от имени Клиента или от имени вышеуказанного другого клиента. ",
      item155:
        "11.1.4 Компания может предоставлять консультацию и оказывать другие услуги любым связанным сторонам или другим клиентам Компании, которые могут быть заинтересованы в Базовых активах или инвестициях, при том, что эта заинтересованность может вступать в конфликт с интересами Клиента.  ",
      item156:
        "11.2 Клиент уполномочивает Компанию осуществлять сделки с Клиентом или от своего имени любым образом, который Компания сочтет подходящим, несмотря на любой конфликт интересов или существование какой-либо материальной заинтересованности сторон в Торговой операции. Клиент соглашается, что Компания не обязана предварительно уведомлять его о совершении вышеуказанных сделок.  ",
      item157:
        "11.3 Сотрудники Компании обязаны соблюдать политику независимости и не учитывать любой конфликт интересов или материальную заинтересованность сторон при предоставлении консультаций Клиенту.  ",
      item158: "12. Персональные данные и запись телефонных звонков",
      item159:
        "12.1 Компания вправе использовать, хранить и обрабатывать персональные данные Клиента, которые он предоставил Компании в связи с оказанием Услуг.  ",
      item160:
        "12.2 Если Клиент является физическим лицом, Компания обязана предоставлять копию документа с персональными данными Клиента по требованию Клиента, при условии оплаты им данной услуги и при условии наличия копии в распоряжении Компании.  ",
      item161:
        "12.3 Клиент согласен с тем, что Компания вправе передавать его данные любым третьим лицам для эффективного оказания Услуг или для эффективного выполнения любой операционной функции, выполняемой в пользу Клиента (например, возмещение Клиенту его средств).  ",
      item162:
        "12.4 Клиент согласен с тем, что любые Телефонные разговоры между ним и Компанией могут быть записаны и что эти записи останутся в исключительной собственности Компании и будут приняты Клиентом как заключительное доказательство Распоряжений / Запросов или телефонных разговоров. Клиент согласен с тем, что Компания вправе предоставлять копии расшифровок вышеуказанных записей любому контролирующему или государственному органу, любому суду.  ",
      item163: "13. Согласие на прямой контакт  ",
      item164:
        "13.1 Клиент согласен с тем, что Компания вправе периодически напрямую связываться с Клиентом по телефону или с использованием иных средств связи в целях маркетинга ее финансовых услуг и продуктов.  ",
      item165: "14. Конфиденциальность и отказ  ",
      item166:
        "14.1 Любая информация о Клиенте, которой владеет Компания, является конфиденциальной и не подлежит использованию в любых других целях, кроме оказания Услуг Компанией Клиенту. Информация конфиденциального характера требует соответствующего Закону обращения при том условии, что она не находится в собственности Компании и на нее не распространялось обязательство по соблюдению конфиденциальности или по неразглашению в момент ее получения Компанией, а также при условии, что она не является общедоступной.  ",
      item167:
        "Конфиденциальная Информация может быть раскрыта Компанией любому лицу исключительно в перечисленных ниже случаях:  ",
      item168: "14.1.1 В соответствии с требованиями Закона. ",
      item169:
        "14.1.2 В целях предотвращения или расследования любой незаконной деятельности, включая мошенничество.  ",
      item170:
        "14.1.3 Когда конфиденциальная информация необходима: в целях, сопутствующих оказанию Услуг, в целях администрирования Торгового счета Клиента, в целях запроса, в целях оценки суммы кредита, в идентификационных целях.  ",
      item171:
        "14.1.4 Когда конфиденциальная информация необходима любому третьему лицу в связи с оказанием Услуг Клиенту и (или) сотрудникам Компании в целях исполнения их служебных обязанностей в соответствии с Нормативно-правовыми документами и (или) юристам, консультантам, аудиторам Компании, при условии, что в каждом конкретном случае соответствующий специалист будет уведомлен о конфиденциальном характере вышеуказанной информации о Клиенте и принял на себя указанные выше обязательства по неразглашению передаваемой ему конфиденциальной информации о Клиенте.  ",
      item172:
        "14.1.5 Когда конфиденциальная информация запрашивается самим Клиентом или другими лицами с письменного согласия Клиента.  ",
      item173:
        "14.1.6 Когда конфиденциальная информация необходима в любом судебном разбирательстве или в рамках любого публичного спора между Клиентом и Компанией.  ",
      item174:
        "14.1.7 Когда конфиденциальная информация необходима по требованию судов, правоохранительных, контролирующих и подобных органов, в юрисдикции которых находится Компания. ",
      item175: "15. Отказ в удовлетворении претензии",
      item176:
        "15.1 Компания имеет право отказать Клиенту в удовлетворении предъявленной им претензии.  ",
      item177:
        "15.2 Претензии, предъявляемые Клиентом в отношении любых невыполненных Компанией Распоряжений, отданных в период проведения плановых технических работ на Сервере, не будут приняты в том случае, если Компания заранее уведомила Клиента о проведении вышеуказанных работ любым способом. Если Клиент не получил вышеуказанное уведомление, то этот факт не служит основанием для предъявления им претензии.  ",
      item178:
        "15.3 Также не будут приняты к рассмотрению претензии, основанные на различии между ценой базового актива Контракта на разницу и ценой исполнения Распоряжения (Ордера) по Контракту на разницу.  ",
      item179:
        "15.4 Не принимаются претензии по времени исполнения Ордера вне зависимости от интервала времени, которое потребовалось Компании на исполнение Ордера, и вне зависимости от времени, когда запись об исполнении Ордера появилась в Лог-файле Сервера. Претензии принимаются только в случаях, когда исполнение Ордера, поставленного в очередь, не было осуществлено в соответствии Регламентом торговых операций.  ",
      item180:
        "15.5 Не принимаются претензии об аннулировании финансовых результатов Торговых операций, совершенных Клиентом на дополнительную маржу, которая образовалась на Торговом счете в результате прибыльной сделки по нерыночной котировке или по котировке, полученной Клиентом в результате явной ошибки Компании и впоследствии отмененной.  ",
      item181:
        "15.6 Клиент признает, что он не сможет контролировать все Открытые позиции или отдавать Распоряжения на открытие новых позиций в тот период времени, пока Спорная ситуация в отношении оспариваемой позиции находится на рассмотрении Компанией, и что никакие претензии по данному вопросу приняты не будут.  ",
      item182:
        "15.7 Компания вправе аннулировать любую Торговую операцию, если соответствующая Хеджинговая торговля была аннулирована Поставщиком ликвидности. Компания вправе не принимать никаких претензий по данному обстоятельству. ",
      item183: "16. Форс-мажорные обстоятельства  ",
      item184:
        "16.1 Компания вправе определять по своему мотивированному усмотрению, произошло ли какое-либо Форс-мажорное обстоятельство и, в случае существования такового, обязуется принять разумные меры по надлежащему уведомлению об этом Клиента. К Форс-мажорным обстоятельствам относятся, не ограничиваясь таковыми, перечисленные ниже случаи.  ",
      item185:
        "16.1.1 Любые действия и события, которые по мотивированному усмотрению Компании не позволяют ей надлежащим образом поддерживать рыночные условия по каким-либо Инструментам, включая (но не ограничиваясь таковыми): террористические акты, войны, массовые и гражданские беспорядки, забастовки, отказ работодателя обеспечить работой своих подчиненных, действия государственных органов; обстоятельства непреодолимой силы, природные катастрофы, пожары, наводнения, штормы, несчастные случаи; сбои в работе поставщика, сбои в подаче электроэнергии, сбои в работе электронного или коммуникационного оборудования.  ",
      item186:
        "16.1.2 Ненаступление или отмена любого события, от которого зависят Котировки Компании; закрытие, приостановка или ликвидация любого рынка; наложение ограничений или установление специальных, нестандартных условий торговли при любом вышеуказанном событии, на любом из вышеуказанных рынков.  ",
      item187:
        "16.2 Если Компания мотивировано полагает, что существует какое-либо Форс-мажорное обстоятельство, она вправе в любое время без предварительного Письменного уведомления Клиента, но и без ущемления любых других прав, возникающих в соответствии с Нормативно-правовыми документами, принимать любые или все из нижеуказанных мер:  ",
      item188: "а) повышать маржинальные требования; ",
      item189:
        "б) закрывать Открытые позиции по ценам, которые Компания обоснованно сочтет подходящими;  ",
      item190:
        "в) изменять, приостанавливать или замораживать применение любых (или всех) условий Нормативно-правовых документов в той мере, в которой Форс-мажорные обстоятельства препятствуют их соблюдение, делают для Компании невозможным или непрактичным их соблюдение;  ",
      item191:
        "г) принимать любые другие меры (воздерживаться от принятия мер), которые Компания обоснованно сочтет подходящими в форс-мажорных обстоятельствах в отношении положения Компании, Клиента и других клиентов. ",
      item192: "17. Неисполнение обязательств  ",
      item193:
        "17.1 К “Случаям неисполнения обязательств” относятся события, перечисленные ниже. ",
      item194:
        "17.1.1 Неисполнение Клиентом любого или нескольких обязательств перед Компанией, указанных в Нормативно-правовых документах. ",
      item195:
        "17.1.2 Непредставление Клиентом любой суммы, подлежащей оплате в соответствии с Нормативно-правовыми документами.  ",
      item196:
        "17.1.3 Если Клиент является физическим лицом, то инициирование любым третьим лицом судебных разбирательств по поводу его банкротства; если Клиент является юридическим лицом, то инициирование любым третьим лицом судебных разбирательств по поводу его ликвидации, либо для назначения администратора или конкурсного управляющего в отношении Клиента и (или) любых активов Клиента; если Клиент, будучи физическим или юридическим лицом, договаривается со своими кредиторами или заключает мировое соглашение с ними, а также, если происходит инициирование против Клиента любых процессуальных действий, которые аналогичны любым вышеуказанным действиям и процессам.  ",
      item197:
        "17.1.4 Если в соответствии с Пунктом 15 Соглашения любое сделанное Клиентом заявление или любая предоставленная гарантия являются или становятся ложными.  ",
      item198:
        "17.1.5 Если Клиент при наступлении срока погашения своих долгов перед Компанией не может их погасить.  ",
      item199:
        "17.1.6 Если Клиент, будучи физическим лицом, становится недееспособным вследствии психического расстройства или умирает.  ",
      item200:
        "17.1.7 В случае наступления любых обстоятельств, когда Компания разумно полагает, что предпочтительным решением будет принятие любых мер, указанных в Пункте 18.5 настоящего Соглашения.  ",
      item201:
        "17.1.8 Если Клиент предпринимает какие-либо попытки к действиям, определяемым Компанией как манипулятивные, мошенничество, своп-арбитраж или другим формам деятельности, вводящим в заблуждение относительно счета (счетов) Клиента, открытого в Компании.",
      item202:
        "17.1.9 Если Клиент вел торговлю, которая может быть охарактеризована как чрезмерная с целью извлечения выгоды от изменений цен на рынке без законных намерений и (или) которую можно отнести к категории рыночных махинаций и (или) которая велась с помощью арбитража и (или) в расчете на отставание цены.",
      item203:
        "17.2 Компания, при наступлении любого из вышеуказанных Случаев неисполнения обязательств, вправе в любое время и без предварительного Письменного уведомления Клиента принять одну или несколько из нижеследующих мер.  ",
      item204:
        "17.2.1 Закрыть любую часть (или все) Открытых позиций Клиента по текущим Котировкам и (или) Торговых счетов Клиента в Компании.  ",
      item205:
        "17.2.2 Списать с Торгового счета Клиента суммы, причитающиеся Компании в соответствии с Соглашением и Нормативно-правовыми документами. ",
      item206: "17.2.3 Отказать Клиенту в открытии нового Торгового счета.  ",
    },
  },

  refund: {
    title: "Политика возврата средств и отмены зачисления средств",
    item1:
      "1. Клиент имеет право пополнять средства торгового счета только с помощью платежных систем, доступных на сайте Компании.",
    item2:
      "2. Фактическим моментом оплаты услуг считается момент, когда все соответствующие средства зачисляются на счет Компании.",
    item3:
      "3. Компания не несет ответственность за результат торговых операций по счету Клиента в случае задержек в зачислении средств на счет. Клиент самостоятельно несет все риски материальных убытков, возникающие в результате возможных задержек в зачислении средств на торговый счет Клиента.",
    item4:
      "4. Компания имеет право отменить зачисление или вывод средств Клиента со счета, если средства на торговый счет Клиента перечисляются третьей стороной или выводятся Клиентом на имя третьей стороны. В этом случае зачисляемые средства перечисляются обратно по реквизитам, с которых они были отправлены. В этом случае с Клиента взимаются все расходы на денежный перевод.",
    item5:
      "5. В случае если на торговый счет поступают средства не в валюте ведения торгового счета, такие средства зачисляются на торговый счет по внутреннему обменному курсу Компании, принятому согласно обменному курсу Банка, обслуживающего Компанию на дату зачисления средств.",
    item6:
      "6. При обработке заявки на снятие средств Компания использует внутренние обменные курсы Компании, принятые согласно обменному курсу Банка, обслуживающего Компанию на дату снятия средств.",
    item7:
      "7. Если пополнение торгового счета не может быть произведено автоматически, соответствующее заявление выполняется в течение суток с момента получения уведомления клиента о пополнении торгового счета.",
    item8:
      "8. В случае если Клиент пополняет свой торговый счет путем денежного перевода непосредственно на банковские реквизиты Компании, он обязан оставить в разделе «Используемый счет» уведомление о пополнении средств.",
    item9:
      "9. Клиент может выводить средства в любые платежные системы, перечисленные в разделе «Используемый счет» под заголовком «Вывод средств».",
    item10:
      "10. При выводе средств с торгового счета, Клиент несет все расходы за перевод средств.",
    item11:
      "11. Средства могут быть выведены только в платежную систему, которая использовалась для зачисления средств на торговый счет и в достаточном объеме торговых операций. Размер достаточного объема торговых операций устанавливается Компанией на ее усмотрение.",
    item12:
      "12. Клиент самостоятельно несет ответственность за адекватность всей информации, указанной в заявлении на вывод средств.",
    item13:
      "13. Компания имеет право отказать Клиенту в выводе средств, если Клиент использует одну и ту же платежную систему для пополнения и вывода средств с торгового счета, но с разными банковскими реквизитами.",
    item14:
      "14. Вывод средств со счета Клиента производится в течение 2 рабочих дней с момента подтверждения соответствующей заявки на вывод средств в разделе «Используемый счет».",
    item15:
      "15. Компания оставляет за собой право вычитать со счета сумму, уплаченную Клиенту в порядке компенсации, если на счете имеется недостаточный объем торговых операций или счет используется для перевода средств из одной платежной системы в другую. Размер достаточного объема торговых операций устанавливается Компанией на ее усмотрение.",
    item16:
      "16. Средства, зачисленные с Кредитной карты, могут быть выведены только после проведения оператором проверки на законность, и в любом случае не ранее чем через 30 дней после зачисления таких средств.",
    item17:
      "17. Средства, зачисленные с Кредитных карт (Банковских карт), а также прибыль, полученная в ходе торговых операций, могут быть выведены только после проведения проверки на законность и не ранее чем через 30 дней с даты последнего пополнения. Средства зачисленные с использованием альтернативных платежных систем (электронные кошельки и электронные валюты), могут быть выведены не ранее чем через 15 дней после первоначального депозита.",
    item18:
      "18. В случае если платежная система не будет работать какое-то время, Компания имеет право перенести дату вывода средств на момент, когда работа платежной системы возобновится.",
    item19:
      "19. Компания имеет право взыскать с Клиента дополнительную комиссию, если Клиент использует разные платежные системы для зачисления и вывода средств. Сумма комиссии, взимаемая с Клиента, рассчитывается исходя из затрат, понесенных Компанией при выполнении заявки Клиента на вывод средств.",
    item20:
      "20. Для вывода средств при помощи механизма обмена (наличными в пункте обмена валют) необходимо заполнить заявку, указав необходимые данные получателя средств: полное имя, фамилию, паспортные данные.",
    item21:
      "21. Компания обязуется предпринять все разумные меры, направленные на предотвращение незаконных финансовых операций и отмывание денег с использованием ресурсов Компании.",
  },

  risk: {
    title: "Заявление о раскрытии рисков",
    item1:
      "Спекулятивный подход к операциям CFD может привести к возникновению рисков в отношении капитала пользователя. По классификации ряда надзорных органов, договоры CFD относятся к высокорисковым инструментам, поскольку они не подразумевают защиту капитала и гарантированную прибыль, и клиенты могут потерять вложенные средства. На Торговой платформе BHP Trade невозможно потерять сумму, превышающую вложенные средства, и клиенты не могут остаться в долгу перед компанией BHP Trade. Торговля инструментами CFD подходит не для всех инвесторов; убедитесь, что вы полностью понимаете возможные риски.",
    item2:
      "Настоящее Заявление о раскрытии рисков входит в состав Клиентских соглашений в соответствии с определением, приведенным в настоящем Пользовательском соглашении.",
    item3:
      "1.1. Вы рассматриваете возможность использования Торговой платформы Компании BHP Trade («Компания», «мы» и т.п.) для совершения операций с договорами на получение разницы («Договоры CFD»). Договоры CFD являются высокорисковыми инвестициями, которые не подходят для многих инвесторов.",
    item4:
      "1.2. В настоящем заявлении содержится информация о рисках, связанных с операциями с CFD, однако в нем не могут быть разъяснены все риски, а также не может разъясняться, как такие риски связаны с вашими личными обстоятельствами. В случае сомнений вам следует обратиться за советом к профессионалам.",
    item5:
      "1.3. Важно, чтобы вы хорошо понимали возможные риски до совершения торговых операций с Компанией. Если вы примите решение о совершении торговых операций с Компанией, важно, чтобы вы помнили о существующих рисках, имели адекватные финансовые ресурсы, достаточные для принятия таких рисков, и чтобы вы внимательно отслеживали свои позиции.",
    title2: "2. ВОЗМОЖНОСТЬ НАСТУПЛЕНИЯ БОЛЬШИХ УБЫТКОВ",
    desc2:
      "2.1. Суть торговли с кредитным плечом заключается в том, что и прибыль и убыток могут быть увеличены, и, за исключением случаев, когда пользователь размещает Заявку на закрытие с убытком или Ограниченную заявку, он может понести очень большие убытки, если цена на открытую им позицию изменится неблагоприятным образом. Однако ваши потери не могут превысить сумму, имеющуюся на Торговом счете.",
    title3: "3. ДОГОВОРЫ CFD НЕ ПОДХОДЯТ ДЛЯ ДОЛГОСРОЧНЫХ ИНВЕСТИЦИЙ",
    desc3:
      "3.1. Договоры CFD не подходят для инвесторов, вкладывающих средства на долгий срок. Если держать позицию CFD открытой слишком долгое время, сопряженные с позицией расходы возрастают, и, возможно, более выгодным окажется приобретение не CFD, а базового актива.",
    title4: "4. ОТСУТСТВИЕ ПРАВ НА БАЗОВЫЙ ИНСТРУМЕНТ",
    desc4:
      "4.1. Договоры CFD не предоставляют никаких прав на базовые инструменты, а в случае договоров CFD на базе акций – прав голоса по таким акциям.",
    title5: "5. ЦЕЛЕСООБРАЗНОСТЬ",
    desc5:
      "5.1. В соответствии с нашими условиями, вы самостоятельно определяете, насколько Торговая платформа подходит для ваших обстоятельств, самостоятельно принимаете решение о том, регистрировать ли вам учетную запись на данной Торговой платформе, и самостоятельно несете все риски.",
    desc6:
      "5.2. Мы также можем запросить у вас информацию о ваших финансовых активах и заработке. Мы не отслеживаем от вашего имени, соответствует ли предоставленная вами информация суммам денег, перечисленным на Торговый счет, а также вашим прибылям и убыткам. Вы самостоятельно оцениваете, достаточно ли у вас финансовых ресурсов, и какую степень риска вы принимаете.",
    title6: "6. ВОЗМОЖНЫЕ РИСКИ",
    desc7:
      "6.1. Вы не можете потерять средств больше, чем имеется на вашем Торговом счете.",
    desc8:
      "6.2. До начала торговых операций CFD на Торговой платформе вам необходимо перечислить Компании средства Начальной маржи, а чтобы Транзакция была открытой какое-то время, необходимо, чтобы сумма на вашем Торговом счете была больше Минимальной маржи. Для разных Инструментов устанавливается разная Начальная маржа; такие сумма указываются на Торговой платформе. Это означает, что вы будете совершать торговые операции с «кредитным плечом», что может оказаться выгодным или, наоборот, невыгодным для вас. Незначительное изменение цены благоприятным для вам образом может принести вам высокую рентабельность Начальной маржи, перечисленной для совершения торговых операций, но незначительное изменение цены в отрицательную для вас сторону может повлечь за собой серьезные убытки.",
    desc9:
      "6.3. Мы также требуем, чтобы на Торговом счете пользователя была сумма, превышающая Минимальную маржу, чтобы Транзакция была открытой какое-то время. Таким образом, если цена изменится неблагоприятным для вас образом, вам, возможно, понадобится оперативно перечислить значительную сумму дополнительной Маржи, чтобы вы могли продолжить торговые операции. Если вы этого не сделаете, Компания будет вправе закрыть одну, несколько или все ваши торговые операции. Вы несете ответственность за все понесенные убытки.",
    desc10:
      "6.4. Вам также следует помнить, что в соответствии с нашим Пользовательским соглашением Компания вправе на свое усмотрение направить вам Требование о внесении дополнительных средств. В соответствии с Пользовательским соглашением, вы обязаны немедленно выполнить любое Требование о внесении дополнительных средств, перечислив деньги переводом в установленные нами сроки. Если вы этого не сделаете, Компания будет вправе закрыть одну, несколько или все ваши торговые операции.",
    desc11:
      "6.5. Вы можете принять меры и установить абсолютный лимит своих убытков (например, размещая на своем счете заявки на закрытие с убытком или закрытие с прибылью); в противном случае, если ситуация на рынке изменится невыгодным для вас образом, вы можете потерять все средства, находящиеся на вашем Торговом счете. Компания предлагает ряд инструментов управления рисками, которыми вы можете воспользоваться.",
    title7: "7. НЕ ИСТОЧНИК ДОХОДА",
    desc12:
      "7.1. Ввиду свойств, присущих договорам CFD, они не подходят для инвесторов, которые ожидают дохода от таких инвестиций, поскольку доход от таких инвестиций в денежном выражении подвержен чрезвычайным колебаниям. Реализация и/или продажа инвестиций во внебиржевые продукты, которые не являются высоколиквидными, может оказаться достаточно сложной задачей, как и получение надежной информации о их стоимости или степени рисков, которым они подвержены.",
    title8: "8. КОЛЕБАНИЯ НА РЫНКЕ",
    desc13:
      "8.1. Важно, чтобы вы понимали риски, связанные с совершением торговых операций на рынке, так как колебания цены на рынке базовых инструментов оказывает влияние на рентабельность торговых операций. Например: стоимость инвестиций в иностранной валюте может увеличиваться или уменьшаться в связи с изменениями обменного курса.",
    desc14:
      "8.2. Следовательно, операции с договорами CFD подходят для клиентов, которые полностью понимают существующие риски и уже имеют опыт совершения торговых операций. Если вы не уверены, обратитесь за советом к независимому специалисту.",
    title9: "9. ДЕРИВАТИВЫ",
    desc15:
      "9.1. Торговые операции по позициям, открытым в Компании, совершаются не на бирже. Компания устанавливает цены и прочие условия, с учетом обязательств Компании предоставить исполнение на наилучших условиях, действовать обоснованно и в соответствии с пользовательским соглашением и Политикой исполнения заявок. Каждый раз, когда вы совершаете торговую операцию CFD на Торговой платформе Компании, вы заключаете договор с Компанией; эти договоры могут быть заключены только с Компанией, и они не могут быть переданы какому-либо другому лицу.",
    title10: "10. НЕОБХОДИМОСТЬ ОТСЛЕЖИВАТЬ ПОЗИЦИИ",
    desc16:
      "10.1. В связи с применением кредитного плеча и скоростью, с которой могут возникать прибыли или убытки, важно внимательно следить за своими позициями. Вы обязаны самостоятельно следить за своими торговыми операциями.",
    title11: "11. ОПЕРАЦИОННЫЕ РИСКИ",
    desc17:
      "11.1. Операционные риски, возможные на сайте нашей компании, которым вы пользуетесь со своего компьютера, присущи каждой транзакции CFD. Например, перебои в рабочих процессах, таких как обеспечение связи, работа компьютеров или мобильных сетей, а также внешние события, могут привести к задержкам в исполнении и совершении транзакции. BHP Trade не несет и не берет на себя ответственность за рабочие процессы компании, за исключением случаев, когда перебои в работе вызванные мошенничеством или недобросовестностью со стороны BHP Trade.",
    title12: "12. ВАЛЮТНЫЙ РИСК",
    desc18:
      "Клиенты должны понимать, что если цена на договор CFD указана не в валюте их страны, такой договор подвержен дополнительному риску колебаний обменного курса.",
  },

  conflict: {
    title: "Политика в отношении конфликта интересов",
    item1:
      "В настоящей Политике в отношении конфликта интересов объясняется, как компания BHP Trade разрешает конфликты интересов, возникающие в компании BHP Trade, между компанией BHP Trade и ее клиентами, и между клиентами компании BHP Trade. В настоящую Политику периодически вносятся изменения с учетом изменений осуществляемой деятельности или методов работы Компании, а также для обеспечения ее соответствия изменениям законодательства, технологий и деловой ситуации в целом. Настоящая политика распространяется на все действия, осуществляемые компанией BHP Trade и ее сотрудниками.",
    item2:
      "1. Компания BHP Trade осуществляет все транзакции с клиентами как принципал, хеджирует свой чистый риск с контрагентами; иными словами, цели клиентов в большей степени скоординированы с ее собственными целями, невзирая на тот факт, что она выступает принципалом во всех транзакциях. Поскольку компания BHP Trade не предоставляет рекомендации о вложении средств, в этой связи не может возникнуть конфликт интересов. Компания BHP Trade предлагает только договоры CFD, что исключает возможность появления конфликтов между различными видами продуктов.",
    item3:
      "2. Компания BHP Trade несет ответственность за выявление и разрешение других конфликтов интересов, которые возникают в ее деятельности и могут повлечь за собой существенный риск нанесения вреда интересам клиентов. В частности, компания BHP Trade рассматривала потенциальные конфликты интересов, возникающие при исполнении транзакций для ее клиентов.",
    item4:
      "3. Для разрешения других конфликтов интересов были приняты следующие меры:",
    item5:
      "(a) Когда компания BHP Trade исполняет заявку клиента, она не отдает предпочтение этому клиенту в ущерб другим клиентам и не раскрывает сведения по заявке одного клиента другим клиентам.",
    item6:
      "(b) Компания BHP Trade исполняет все заявки в электронном виде, в соответствии с политиками, обеспечивающими соблюдение применимых правил и норм касательно транзакций, исполняемых от имени клиентов. Процедуры, принятые в компании BHP Trade, обеспечивают оперативное, справедливое и эффективное исполнение заявок клиентов по сравнению с другими заявками. Эти процедуры обеспечивают исполнение сопоставимых в остальных отношениях заявок по времени их поступления.",
    item7:
      "(c) В Компании BHP Trade разработана процедура, согласно которой исполнение заявок от имени клиентов оперативно и точно регистрируется, сопоставимые в остальных отношениях заявки учитываются последовательно и оперативно, за исключением случаев, когда из-за характеристик заявки или текущей ситуации на рынке такое действие является непрактичным, или иное требуется в интересах клиента.",
    item8:
      "(d) Согласно процедурам компании BHP Trade, компания BHP Trade, ее сотрудники и аффилированные лица не могут неправомерно использовать информацию о заявках клиентов, ожидающих исполнения.",
    item9:
      "(e) Всем сотрудникам компании BHP Trade запрещено проводить торговые операции с использованием личных счетов с целью избежать фактического или подозреваемого конфликта интересов.",
    item10:
      "(f) Всем сотрудникам компании BHP Trade запрещается принимать подарки и прочие формы поощрения от любого лица, имеющего материальную заинтересованность, которая с определенной долей вероятности в значительной степени противоречит обязанностям компании BHP Trade и/или ее сотрудников в связи с политикой справедливого обращения с клиентами или обязанностям такого получателя по отношению к клиентам.",
    item11:
      "4. Компания BHP Trade стремится к формированию эффективной надлежащей культуры соблюдения законодательных требований, позволяющей разрешать любые возможные конфликты интересов, которые могут возникнуть в дальнейшем. Следовательно, сотрудники компании BHP Trade обязаны отслеживать любые новые обстоятельства, которые могут привести к возникновению конфликтов, и внедрять меры, необходимые для их разрешения.",
    item12:
      "5. В редких случаях, когда принятый в компании BHP Trade порядок разрешения конфликтов интересов не позволяет с высокой вероятностью гарантировать предотвращение рисков нанесения вреда клиенту, компания BHP Trade должна четко разъяснить клиенту общий характер и/или источники конфликтов интереса до выполнения каких-либо операций для клиентов.",
    title2: "Как и когда с нами связаться",
    item13:
      "Если у вас возникли вопросы о Политике в отношении конфликта интересов, или вы хотите помочь нам улучшить эту Политику, вы можете связаться с нами, написав нам на адрес электронной почты: info@bhp-trade.com",
  },
};
