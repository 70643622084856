import React from "react";

const IconCertificate = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={fill}
        d="M11.25,17a.75.75,0,0,1-.75.75H5A3.383,3.383,0,0,1,1.25,14V6A3.383,3.383,0,0,1,5,2.25H17A3.383,3.383,0,0,1,20.75,6a.75.75,0,0,1-1.5,0c0-1.577-.673-2.25-2.25-2.25H5c-1.577,0-2.25.673-2.25,2.25v8c0,1.577.673,2.25,2.25,2.25h5.5A.75.75,0,0,1,11.25,17ZM12,7.25H6a.75.75,0,0,0,0,1.5h6a.75.75,0,0,0,0-1.5Zm-3,5.5a.75.75,0,0,0,0-1.5H6a.75.75,0,0,0,0,1.5Zm13.107,2.134L21.9,15.1l.008.279a2.187,2.187,0,0,1-1.175,1.931l.994,3.487a.75.75,0,0,1-1,.9l-1.847-.74a3.252,3.252,0,0,0-2.417,0l-1.845.739a.751.751,0,0,1-1-.9l.994-3.487a2.189,2.189,0,0,1-1.174-1.931v-.3l-.2-.193a2.2,2.2,0,0,1,0-3.1l.213-.213-.008-.278A2.2,2.2,0,0,1,15.624,9.1h.3l.191-.2a2.246,2.246,0,0,1,3.1,0l.212.211.28-.007A2.2,2.2,0,0,1,21.9,11.291v.3l.2.193A2.195,2.195,0,0,1,22.107,14.884Zm-2.248,4.851-.578-2.028-.064.067a2.192,2.192,0,0,1-3.1,0l-.064-.064-.577,2.025.426-.172a4.752,4.752,0,0,1,3.531,0Zm1.188-6.892-.212-.212a1.469,1.469,0,0,1-.432-1.042v-.3a.694.694,0,0,0-.693-.694h-.3a1.462,1.462,0,0,1-1.043-.433l-.21-.21a.709.709,0,0,0-.982,0l-.211.211a1.468,1.468,0,0,1-1.042.432h-.3a.694.694,0,0,0-.693.694v.3A1.464,1.464,0,0,1,14.5,12.63l-.214.213a.7.7,0,0,0,0,.981l.213.213a1.458,1.458,0,0,1,.432,1.04v.3a.694.694,0,0,0,.693.694h.3a1.466,1.466,0,0,1,1.04.43l.214.214a.709.709,0,0,0,.98,0l.214-.213a1.457,1.457,0,0,1,1.04-.43h.3a.694.694,0,0,0,.693-.694v-.3a1.462,1.462,0,0,1,.43-1.039l.214-.214A.7.7,0,0,0,21.047,12.843Zm-.964.49a2.417,2.417,0,1,1-2.416-2.416A2.419,2.419,0,0,1,20.083,13.333Zm-1.5,0a.917.917,0,1,0-.916.917A.917.917,0,0,0,18.583,13.333Z"
      />
    </svg>
  );
};

export default IconCertificate;
