import React from "react";

const IconBriefcase = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 5C9.89543 5 9 5.89543 9 7H7C7 4.79086 8.79086 3 11 3H13C15.2091 3 17 4.79086 17 7H15C15 5.89543 14.1046 5 13 5H11ZM20 13.3874L21.3162 12.9487C21.7246 12.8126 22 12.4304 22 12V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V12C2 12.4304 2.27543 12.8126 2.68377 12.9487L4 13.3874V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V13.3874Z"
        fill={fill}
        fill-opacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M6 9C4.89543 9 4 9.89543 4 11V11.2792L9.15395 12.9972C11.0014 13.613 12.9986 13.613 14.846 12.9972L20 11.2792V11C20 9.89543 19.1046 9 18 9H6ZM2 11C2 8.79086 3.79086 7 6 7H18C20.2091 7 22 8.79086 22 11V12C22 12.4304 21.7246 12.8126 21.3162 12.9487L15.4785 14.8946C13.2206 15.6472 10.7794 15.6472 8.52149 14.8946L2.68377 12.9487C2.27543 12.8126 2 12.4304 2 12V11Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconBriefcase;
