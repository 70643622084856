import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../contexts/LanguageContext";
import { languages } from "../i18n";
import { Button } from "antd";

const NavBar = () => {
  const { t } = useTranslation();
  const basePath = "navBar.";
  const { changeLanguage } = useLanguage();
  const activeLang = localStorage.getItem("i18nextLng");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("lock", isActive);
    return () => {
      document.body.classList.remove("lock");
    };
  }, [isActive]);

  const handleChangeLang = (e) => {
    changeLanguage(e.target.dataset.value);
  };

  const handleClick = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <nav className="nav index">
      <div className="nav-wrapper">
        <a className="nav-logo" href="/">
          <img src={require("../assets/img/logo.svg").default} alt="" />
        </a>

        <div className="nav-btns">
          <div className="select-langs">
            <div className="current-lang">
              <span>{t(`${basePath}lang.${activeLang}`)}</span>
            </div>
            <div className="nav-langs-box">
              <ul className="list">
                {languages.map((item) => (
                  <li className="item" onClick={handleChangeLang}>
                    <div className="item_lang" data-value={item}>
                      <span>{t(`${basePath}lang.${item}`)}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Button type="primary" className="xs">
            Register
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
