import { Col, Row } from "antd";
import React from "react";
import { IconClipboardData, IconCpu, IconShieldCheck } from "../../utils/Icons";

const Features = () => {
  return (
    <section className="features">
      <Row justify="center">
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="text-center">
          <div className="section__title">Our features</div>
          <p>
            Whether you're a beginner or an experienced trader, our platform
            empowers you to make informed decisions and your trading success.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <div className="box text-center">
            <div className="icon orange">
              <IconShieldCheck width="24" height="24" fill="#fff" />
            </div>
            <h4>Safety</h4>
            <p className="m-0">
              We do not have access to your personal data and cannot withdraw
              funds
            </p>

            <span class="screw screw-lines-2"></span>
            <span class="screw screw-lines-3"></span>
            <span class="screw screw-lines-4"></span>
          </div>
        </Col>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <div className="box text-center">
            <div className="icon green">
              <IconCpu width="24" height="24" fill="#fff" />
            </div>
            <h4>Automatization</h4>
            <p className="m-0">
              Automatic arbitrage calculations and deal closing
            </p>

            <span class="screw screw-lines-2"></span>
            <span class="screw screw-lines-3"></span>
            <span class="screw screw-lines-4"></span>
          </div>
        </Col>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <div className="box text-center">
            <div className="icon blue">
              <IconClipboardData width="24" height="24" fill="#fff" />
            </div>
            <h4>Analytics</h4>
            <p className="m-0">
              The robot shows you how your trades are performing in real time
            </p>

            <span class="screw screw-lines-2"></span>
            <span class="screw screw-lines-3"></span>
            <span class="screw screw-lines-4"></span>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Features;
