import { Col, Row } from "antd";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  const settings = {
    dots: false,
    arrows: false,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="partners">
      <Row justify="center">
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="text-center">
          <div className="section__title">Our partners</div>
          <p>
            We take pride in collaborating with our partners who help us provide
            the best services to our clients. If you'd like to become our
            partner, please contact us.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Slider {...settings}>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-1.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-2.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-3.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-4.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-5.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-6.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-7.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-8.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-9.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
            <div>
              <div className="item">
                <img
                  src={require("../../assets/img/partners/img-10.png")}
                  alt=""
                />
                <span class="screw screw-lines-1"></span>
                <span class="screw screw-lines-2"></span>
                <span class="screw screw-lines-3"></span>
                <span class="screw screw-lines-4"></span>
              </div>
            </div>
          </Slider>
        </Col>
      </Row>
    </section>
  );
};

export default Partners;
