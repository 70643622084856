import { Button, Col, Progress, Row, Slider } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import IconGift from "../../utils/Icons/elements/IconGift";

const Banner = () => {
  const { t } = useTranslation();
  const basePath = "home.banner.";

  return (
    <section className="banner">
      <Row>
        <Col xl={14} lg={14} md={24} sm={24} xs={24}>
          <h1>
            <b>NEURAL TECHNOLOGY</b>
            <br />
            FOR ARBITRAGE TRADING IN THE CRYPTO INDUSTRY
          </h1>
          <div className="btn-group">
            <Button type="primary">Register</Button>
            <Button type="default">About us</Button>
          </div>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <div className="box">
            <div className="icon red">
              <IconGift width="24" height="24" fill="#fff" />
            </div>
            <h4>Registration bonus</h4>
            <p>
              Sign up and take part in the monthly drawing of{" "}
              <b>$500 deposit bonus!</b> The number of contestants is limited.
            </p>

            <p className="slider-text">Contestants:</p>

            <Slider
              marks={{ 0: "0", 50: "50", 70: "70", 100: "100" }}
              defaultValue={70}
              disabled
            />

            <span class="screw screw-lines-2"></span>
            <span class="screw screw-lines-3"></span>
            <span class="screw screw-lines-4"></span>
          </div>
        </Col>
      </Row>
      <Row className="gains">
        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
          <div className="item">
            <h4>1839</h4>
            <p>Days on the market</p>
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
          <div className="item">
            <h4>5812</h4>
            <p>Members</p>
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
          <div className="item">
            <h4>$374 103</h4>
            <p>Arbitrage pools</p>
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
          <div className="item">
            <h4>$100 812</h4>
            <p>Total paid</p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Banner;
