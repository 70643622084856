import { Col, Row } from "antd";
import React from "react";
import { IconBriefcase, IconDollar } from "../../utils/Icons";

const Token = () => {
  return (
    <section className="token">
      <Row justify="center">
        <Col xl={11} lg={11} md={24} sm={24} xs={24}>
          <div className="box">
            <div className="icon orange">
              <IconDollar width="24" height="24" fill="#fff" />
            </div>
            <h4>For Investors</h4>
            <p className="m-20">
              - <b>6 types</b> of arbitrage pools with monthly <b>10%</b> yield
              and the possibility to reinvest on more favorable terms;
            </p>
            <p className="m-20">
              - <b>Arbitrage</b> nodes to boost your pool;
            </p>
            <p className="m-0">- Other rewards and bonuses;</p>

            <span class="screw screw-lines-2"></span>
            <span class="screw screw-lines-3"></span>
            <span class="screw screw-lines-4"></span>
          </div>
        </Col>
        <Col xl={11} lg={11} md={24} sm={24} xs={24}>
          <div className="box">
            <div className="icon blue">
              <IconBriefcase width="24" height="24" fill="#fff" />
            </div>
            <h4>Token</h4>
            <div class="rate-wrap">
              <div class="rate">
                <span>Current course</span>
                <p>1.00 Token = $1.00</p>
              </div>

              <div class="graph">
                <img
                  src={require("../../assets/img/graph/graph2.svg").default}
                  alt=""
                />
              </div>
            </div>

            <div class="rate-wrap">
              <div class="rate">
                <span>Week</span>
                <p class="green">
                  +1.00% <small>[0.84]</small>
                </p>
              </div>

              <div class="graph">
                <img
                  src={require("../../assets/img/graph/graph1.svg").default}
                  alt=""
                />
              </div>
            </div>

            <span class="screw screw-lines-2"></span>
            <span class="screw screw-lines-3"></span>
            <span class="screw screw-lines-4"></span>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Token;
