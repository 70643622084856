import React from "react";

const IconGift = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M6 13V17C6 18.1046 6.89543 19 8 19H16C17.1046 19 18 18.1046 18 17V13H20V17C20 19.2091 18.2091 21 16 21H8C5.79086 21 4 19.2091 4 17V13H6Z"
        fill={fill}
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M9 5C7.89543 5 7 5.89543 7 7H11C11 5.89543 10.1046 5 9 5ZM12 4.35418C11.2671 3.52376 10.1947 3 9 3C6.79086 3 5 4.79086 5 7H4C3.44772 7 3 7.44772 3 8V12C3 12.5523 3.44772 13 4 13H11V19H13V13H20C20.5523 13 21 12.5523 21 12V8C21 7.44772 20.5523 7 20 7H19C19 4.79086 17.2091 3 15 3C13.8053 3 12.7329 3.52376 12 4.35418ZM13 7H17C17 5.89543 16.1046 5 15 5C13.8954 5 13 5.89543 13 7ZM5 11V9H19V11H5Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconGift;
