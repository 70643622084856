import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <footer>
      <section>
        <Row justify="center">
          <Col xl={10} lg={10} md={24} sm={24} xs={24}>
            <div className="item">
              <a href="/">
                <img
                  src={require("../assets/img/logo.svg").default}
                  alt="logo"
                  title="logo"
                />
              </a>
              <p>
                The Contribution Aggregator team works hard to deliver
                exceptional financial results and increase our clients' revenue.
              </p>
              <div className="crypto">
                <img
                  src={require("../assets/img/crypto/btc.svg").default}
                  alt=""
                />
                <img
                  src={require("../assets/img/crypto/eth.svg").default}
                  alt=""
                />
                <img
                  src={require("../assets/img/crypto/sol.svg").default}
                  alt=""
                />
                <img
                  src={require("../assets/img/crypto/bnb.svg").default}
                  alt=""
                />
                <img
                  src={require("../assets/img/crypto/xrp.svg").default}
                  alt=""
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="copy">
              <p>© Contribution Aggregator, 2024. all rights reserved.</p>
              <div className="social">
                <a href="/">
                  <img
                    src={require("../assets/img/social/fb.svg").default}
                    alt=""
                  />
                </a>
                <a href="/">
                  <img
                    src={require("../assets/img/social/inst.svg").default}
                    alt=""
                  />
                </a>
                <a href="/">
                  <img
                    src={require("../assets/img/social/tl.svg").default}
                    alt=""
                  />
                </a>
                <a href="/">
                  <img
                    src={require("../assets/img/social/tw.svg").default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      <span class="screw screw-lines-1"></span>
      <span class="screw screw-lines-2"></span>
      <span class="screw screw-lines-3"></span>
      <span class="screw screw-lines-4"></span>
    </footer>
  );
};

export default Footer;
