export const TRANSLATIONS_EN = {
  navBar: {
    links: {
      item1: "Security",
      item2: "Terminal",
      item3: "About us",
      item4: "Contacts",
      item5: {
        title: "Our partners",
        list1: "Liquidity Providers",
        list2: "Licenses",
      },
    },

    lang: {
      "en-US": "English",
      "ru-RU": "Русский",
    },

    buttons: {
      login: "Login",
      register: "Registration",
    },
  },

  home: {
    banner: {
      title: "Explore a world of opportunities",
      desc: "Our company provides you with a unique opportunity to immerse yourself in a world of innovation and investment.",
      btn: "Become an investor",
    },

    CTA: {
      title: "Time to act!",
      desc: " We offer a unique opportunity to unlock your investment potential and earn money.",
      btn: "Become an investor",
    },

    counter: {
      title: "Our figures",
      lists: {
        item1: "Daily turnover",
        item2: "Total client balance",
        item3: "Active traders",
        item4: "Licenses",
        item5: "Liquidity Providers",
      },
    },

    awards: {
      title: "Our awards and achievements",
      lists: {
        item1: {
          title: "Leading Professional Account",
          desc: "Business Tabloid Awards",
        },
        item2: {
          title: "Fastest Growing Broker",
          desc: "Global 100 Awards",
        },
        item3: {
          title: "Active Development",
          desc: "M&A Today",
        },
        item4: {
          title: "Most Honest Broker",
          desc: "Global Brands Magazine",
        },
        item5: {
          title: "Most reliable trading terminal",
          desc: "IAFT Awards",
        },
        item6: {
          title: "Best Professional Account",
          desc: "Blue Ribbon Winner",
        },
      },
    },

    steps: {
      title: "It's easy to start trading",
      step: "Step",
      item1: "Sign up",
      item2: "Make a deposit",
      item3: "Trade",
    },

    accounts: {
      title: "Accounts: types and features",
      btn: "Become an investor",

      lists: {
        item1: {
          title: "Elementary",
          desc: "This type of account usually provides limited access to the features and functionality of the platform. It is often used for familiarization with the service or basic work.",
        },

        item2: {
          title: "Medium",
          desc: "Best offer: provides more functionality than the entry level. Includes additional features and more benefits.",
        },

        item3: {
          title: "Professional",
          desc: "Professional account provides the most access and advanced features. Includes an additional list of analytical tools, personal support, etc.",
        },

        option: {
          deposit: "Minimum deposit:",
          shoulder: "Leverage:",
          items: {
            option1: "Instant execution of trades",
            option2: "Cryptocurrency trading",
            option3: "Opening a personal PAMM account",
            option4: "Access to ETFs and indices",
            option5: "Personal manager",
            option6: "Deposit insurance",
            option7: "No swap",
          },
        },
      },
    },
  },

  about: {
    banner: {
      title: "Your partner in the financial world",
      desc: "Learn about our goals and the advantages that make us the best in the industry.",
      btn: "Become an investor",
    },

    licenses: {
      title: "Licenses",
      btn: "More details",
    },

    advantages: {
      title: "Platform advantages",
      lists: {
        item1: {
          title:
            "We provide a personalized approach to help you achieve your financial goals, whether it's saving, investing or securing financial stability.",
          desc: "It's the value of your finances",
        },
        item2: {
          title:
            "We have a variety of instruments and markets for you to trade. Whether you're interested in stocks, cryptocurrencies, currencies or commodities, we provide access to a variety of markets.",
          desc: "Huge number of trading opportunities",
        },
        item3: {
          title:
            "We have designed a platform that makes trading easy even for novice investors. Our user interface is intuitive, and analytical tools provide all the information you need to make informed investment decisions.",
          desc: "Our accessible platform",
        },
      },
    },

    history: {
      title: "History of our company",

      lists: {
        item1: {
          title: "Exalted beginnings",
          desc: "In these years, we started out with ambitious dreams and a dedicated team. Began this journey that changed us forever, and solidified our roots in the industry.",
        },
        item2: {
          title: "Growth and Experience",
          desc: "This period was marked by rapid growth. We strengthened our services, gained new clients and gained valuable experience in financial and investment management.",
        },
        item3: {
          title: "Innovation and development",
          desc: "During these years, we continued to innovate change and experiment. Our platform became more advanced and our services became even more satisfactory.",
        },
        item4: {
          title: "The future is on the horizon",
          desc: "Our eyes are fixed on the future, with endless possibilities. We continue to strive to improve and support our clients, giving them financial stability and confidence.",
        },
      },
    },
  },

  platform: {
    banner: {
      title: "Trading terminal",
      desc: " Our innovative trading solutions allow you to control and improve your strategy on the financial markets. Learn more about our platform and start effective trading today.",
      btn: "Become an investor",
    },

    steps: {
      lists: {
        item1: {
          title: "Analytical tools",
          desc: "Our trading platform gives you access to advanced analytical tools to help you make informed decisions.",
        },
        item2: {
          title: "Safety and security",
          desc: "We provide the highest level of security for your financial data and trades so you can trade with confidence.",
        },
        item3: {
          title: "Customization",
          desc: "Our platform adapts to your needs, allowing you to customize the interface and instruments according to your requirements.",
        },
      },
    },

    orders: {
      title: "Types of orders on the markets",

      lists: {
        item1: {
          title: "Market order",
          desc: "Executed immediately at the current market price. It means that you are ready to buy or sell at the most favorable price on the market at the moment.",
        },
        item2: {
          title: "Limit order",
          desc: "A limit order allows you to specify a specific price at which you want to buy or sell. The order will wait for the market price to reach the price you specify and then it will be automatically executed.",
        },
        item3: {
          title: "Stop order",
          desc: "This is an order that is activated when the market price reaches a certain level, called a stop price. When the stop price is reached, the order becomes a market order and is executed at the current price.",
        },
        item4: {
          title: "Stop Loss order",
          desc: "It is set at a certain price level and if the market price reaches that level, the order is automatically activated, helping to protect the investor from additional losses.",
        },
        item5: {
          title: "Take Profit order",
          desc: "A Take Profit order allows you to automatically close a position when the price reaches a specified profit level. You specify a profit target and when the market price reaches that target, the order is automatically executed.",
        },
        item6: {
          title: "Trailing Stop order",
          desc: "A trailing stop order adjusts to the price movement. You specify an initial stop level, and when the price moves in your favor, the stop level is automatically raised, maintaining the specified distance from the current price.",
        },
      },
    },

    description: {
      lists: {
        item1: {
          title: "Trade from the charts",
          subtitle: "Featured functionality",
          desc: "The trading client terminal provides extensive capabilities to execute trades directly from the chart of a financial instrument. Combined with your chosen strategy, this allows you to quickly open, edit and close positions, as well as manage pending orders.",
        },
        item2: {
          title: "API key",
          subtitle: "Get access to new features",
          desc: "Our platform has an exceptional feature that is our hallmark - the API Key. In fact, this tool serves to provide you with top-notch support, improve your experience with the terminal, and access the best possible functionality.",
        },
        item3: {
          title: "Manage your account",
          subtitle: "The key to financial confidence",
          desc: "Account management is a financial management strategy that has many benefits: effective financial planning, reduced financial risk, increased savings, etc. Account management requires discipline and self-discipline, but in the end it brings many financial benefits.",
        },
      },
    },
  },

  security: {
    banner: {
      title: "Safety",
      desc: "Your safety is our priority. We work hard to give you the peace of mind to use our platform knowing that you are in good hands.",
      btn: "Become an investor",
    },

    description: {
      title: "Reliability and security standards",

      lists: {
        item1: {
          title: "Advanced encryption technologies",
          desc: "We utilize advanced encryption technologies to protect your data and transactions, ensuring the highest level of privacy.",
        },
        item2: {
          title: "Two-factor authentication",
          desc: "To enhance the security of your account, we offer two-factor authentication, which requires additional verification when logging in and making transactions.",
        },
        item3: {
          title: "Regular audits and monitoring",
          desc: "We regularly audit and monitor our systems to identify and prevent any potential security threats.",
        },
        item4: {
          title: "Separation of customer funds",
          desc: "Our clients' funds are held in separate accounts and strictly segregated from corporate funds, ensuring their availability and security.",
        },
      },
    },

    collaterals: {
      title: "Collateralization",
      desc: "We are able to meet our financial obligations on time and fully cover your deposits with our assets.",
    },
  },

  licenses: {
    banner: {
      title: "Licenses",
      desc: "We have established ourselves in the industry as a company with a myriad of relationships with various international financial institutions. We pride ourselves on our impeccable reputation and our operations are strictly monitored by various financial regulators, ensuring your safety and trust.",
      btn: "Become an investor",
    },

    lists: {
      item1:
        "This is the financial regulator in Dubai. The DFSA regulates a wide range of financial institutions including banks, insurance companies, investment funds, etc. It also strives to ensure stability and transparency in financial transactions.",
      item2:
        "This is an acronym that stands for Financial Monitoring and Control. It is an important financial regulator in the UK, responsible for supervising and regulating financial markets and financial companies in the country.",
      item3:
        "This is the Cyprus Securities and Exchange Commission. It is the official regulator of financial markets on the island of Cyprus and one of the key financial regulatory bodies in the European Union.",
      item4:
        "This is a regulatory body that is responsible for the control and supervision of financial markets in South Africa. It was established to ensure transparency and integrity in the financial industry and protect the interests of investors.",
    },
  },

  regulators: {
    banner: {
      title: "Liquidity providers",
      desc: "We are proud that our partners are the leading liquidity providers on the market. Their rich portfolios, experience and their advanced technology enable them to provide our clients with reliable and high quality access to financial resources to help them achieve successful investment and trading results.",
      btn: "Become an investor",
    },

    tabs: {
      item1:
        "Provides a wide range of financial services, including corporate and retail banking, investment banking, asset management, insurance and more.",
      item2:
        "It is the largest American global bank and financial corporation. The company is widely recognized for its role in investment banking, asset management, securities trading and other financial services.",
      item3:
        "It is an American financial services company that provides a wide range of investment and financial advisory services. The company has become one of the largest financial organizations in the United States.",
      item4:
        "This is a financial corporation with an impressive portfolio of financial services. The company has become one of the global leaders in investment banking, asset management and other financial services.",
      item5:
        "Provides a variety of banking services, covering all areas of banking. This includes retail banking for individual customers and small businesses, as well as services for large companies.",
      item6:
        "Provides a wide range of financial services including bank accounts, credit cards, mortgages, investment banking and asset management. The bank has many branches and ATMs.",
      item7:
        "Has many clients around the world and provides services to both individuals and corporate clients. The bank is also active in social responsibility by supporting various charitable programs.",
      item8:
        "It is one of the largest financial institutions in the world and plays a key role in the international system of finance. It ranks among the key liquidity providers, ensuring the stability and functioning of many financial markets.",
    },
  },

  contacts: {
    banner: {
      title: "Our contacts",
      desc: "Contact us to find out more about your concerns.",
      btn: "Become an investor",
    },

    info: {
      title: "Contact Information",
      email: "Email",
      address: "Address",
    },

    form: {
      title: "Ask us a question",
      desc: "Contact us to find out more about your concerns.",
      lastName: "Your name *",
      firstName: "Your last name *",
      phone: "Your phone number *",
      email: "Your Email *",
      mass: "Message",
      btn: "Send",
      required: "required field",
    },
  },

  documents: {
    lists: {
      item1: "User Agreement",
      item2: "Withdrawal & Refund Policy",
      item3: "Risk Disclosure Notice",
      item4: "Anti-money Laundering",
      item5: "Conflict of interest policy",
    },
  },

  footer: {
    markets: {
      title: "Markets",
      lists: {
        item1: "Commodities",
        item2: "Metals",
        item3: "Indices",
        item4: "Stocks",
        item5: "Currencies",
        item6: "Cryptocurrencies",
      },
    },

    sections: {
      title: "Sections",
      lists: {
        item1: "Security",
        item2: "Terminal",
        item3: "About us",
        item4: "News",
        item5: "Contacts",
      },
    },

    partners: {
      title: "Our partners",
      lists: {
        item1: "Liquidity Providers",
        item2: "Licenses",
      },
    },

    desc: {
      address:
        "Its registered office is at Viktualienmarkt 8, 80331 München, Germany",
      copy: "Protected by SSL. © 2024 BHP Trade Copyright. All rights reserved.",
      title: "BHP Trade is a financial services company.",
      info1:
        "The company is regulated and supervised by the Dubai Financial Services Authority (DFSA). Investment Dealer License Number: K542681. We are also regulated by the Cyprus Securities and Exchange Commission (CySEC) - investment license - CIF 547/84.",
      info2:
        'Disclaimer: Contracts for Difference ("CFD") products and services are not intended for use by residents of any country or jurisdiction where such distribution or use is contrary to local law.',
    },
  },

  agreement: {
    title: "Terms and definitions",
    lists: {
      item1:
        "1.1 This document, which is a Client Agreement (hereinafter referred to as the Agreement), uses the following definitions and terms: ",
      item2:
        '"Client” – means any natural person or any legal entity that has completed the Client Registration Form, except for those individuals who have not reached the age of eighteen and who do not have a citizenship. ',
      item3: "“The Company” BHP Trade ",
      item4:
        "Services – means the Company's services that it provides to the Client and which are specified in Paragraph 4 of the Agreement. ",
      item5:
        "Website – the Company's website, as well as any other website recognized by the Company as official and periodically maintained by it for the access of clients. ",
      item6:
        "Trading Platform – all programs or technical means of the Company, consisting of a Server and a Client Terminal, which conduct Trading Operations, allow to receive Quotes in real time, allow to place/ change/ delete / execute Orders and calculate all mutual obligations between the Client and the Company. ",
      item7:
        '"Server" – is a software product through which the Company processes client Requests and Orders, provides the Client with information about trading on the financial markets in real time and in the volume determined by the Company, keeps records of mutual obligations with the Client in accordance with the terms of Regulatory Documents. ',
      item8:
        '"Client registration form" – an application form that the Client must fill out to register himself as a Client in the "Personal Cabinet" service and which is available on the Company\'s Website. ',
      item9:
        "\"Access Data\" – means the Client's authorization and access codes, the Client's password and Trading Account number, and any information necessary for the Company to issue Orders to the Client. ",
      item10:
        '"Customer Data" – means any documents or information received by the Company from the Client himself or in relation to the Client, his Account, provide or use of Services. ',
      item11:
        '"Data submission date" – means the date of data submission agreed upon by the parties. ',
      item12:
        '"Contract Specification" – the main trading conditions that apply to each Instrument displayed on the Company\'s Website: Lot Size, Spread, Initial Margin, Hedged Margin. ',
      item13:
        '"Trading operation"– means any deal that is concluded by the Client or on behalf of the Client in accordance with the Agreement and the Rules of Operations. ',
      item14:
        "“The volume of Trading operation” – calculated by the formula: lot size x number of lots. ",
      item15:
        '"Trading Account" – is a unique personalized system in which all full completed transactions, Open Positions, Orders and transactions for depositing or withdrawing funds in the Trading Platform are registered. ',
      item16:
        '"Funds" – the current state of the Client\'s account, determined by the formula: Balance + Floating profit – Floating loss. ',
      item17:
        '"Trading Account Currency" – the currency chosen by the Client when opening a Trading Account. ',
      item18:
        '"Base Currency" – the first currency in the Currency Pair, at the exchange rate of which the Client buys or sells the quoted Currency. ',
      item19:
        '"Quote Currency" – is the second currency in the Currency Pair that the Client buys or sells in order to get the Base Currency. ',
      item20:
        'A "Currency pair" – is an object of a Trading Operation based on a change in the value of one currency in relation to another. ',
      item21:
        '"Balance” – the financial result of all Completed deals and money transfer operations that were carried out on the Trading Account. ',
      item22:
        "“Long position” – is the purchase of an Instrument with the expectation of a rate increase or in respect of currency pairs buying the Base currency. by the Quote Currency. ",
      item23:
        '"Short position" – a profitable deal for the sale of a currency when market prices fall, or in relation to Currency pairs, the selling rate of the Base currency relative to the Quote Currency. ',
      item24:
        '"Quote” – information about the current price for a particular Instrument in the form of Ask or Bid Prices. ',
      item25:
        '"Indicative Quote" – a Quote for which the Company has the right not to accept any instructions or not to execute any Orders. ',
      item26:
        '"Quote Database" – information about the Flow of quotes, which is stored on the Company\'s Server. ',
      item27:
        '"Quote flow" – the process of receiving Quotes for Instruments in the Trading Platform. ',
      item28:
        '"Request” or” Request for quotation" – the Client\'s instruction to the Company to receive a quotation, which is not an obligation to make a deal. ',
      item29:
        '"Erroneous quotes" – quotes that were received in the Client Terminal due to a system technical error. ',
      item30: '"Rate" – means: ',
      item31:
        "1) In the case of a Precious Metal, the price per troy ounce of the Precious Metal in the currency specified for the Instrument in the Contract Specification; or ",
      item32:
        "2) For a Currency Pair, the unit value of the Base Currency in the Quote Currency. ",
      item33:
        '"Instrument" – means the Currency Pair, Contract for Difference, Spot Metals and any other financial instruments of the Company that it offers to the Client. ',
      item34:
        "'Credit leverage' or “The ratio between borrowed and own funds – the ratio 1:25, 1:50, 1:100, 1:200, 1:500, 1:1000 Trading operation volume and the Initial margin. The ratio of 1: 100 for opening a position means that the Initial margin is one hundred times less than the Volume of the Trading Operation. Other ratios may also be published on the Company's website. ",
      item35:
        '"Margin” or "Collateral"– is the monetary security required by the Company to maintain Open Positions. It is specified in the Contract Specification for each Instrument. ',
      item36:
        '"Margin trading"– term deals in which the Client can trade Instruments with significantly less funds in the Trading Account than is necessary for the volume of the Trading Operation. ',
      item37:
        '"Illegal income"–means income received as a result of: violation of any or several provisions of Regulatory Documents, Erroneous Quotation or occurrence of a Case of Default. ',
      item38:
        '"Required Margin"–is the monetary security required by the Company to maintain open positions, specified for each Instrument on the Company\'s Website in the Contract Specification. ',
      item39:
        '"Agreed procedure" – means any procedure agreed upon by the Parties relating to a Disputed Situation, except for such a Procedure for resolving a Disputed Situation, in which the Parties have the right to make changes. ',
      item40:
        '"Order" – the Client\'s order to open or close a position when the price reaches the order level. ',
      item41:
        '"Stop Loss" – A Trading order to close a previously opened position at a price less favorable to the Client than the current price at the time of placing the Order. ',
      item42:
        '"Stop Out" – an order to forcibly close a position, which the Server generates in case of lack of funds to maintain an Open Position. The position is closed without the need to obtain consent from the Client and without prior notice. ',
      item43:
        '"Take Profit" – is a Trading order to close a previously opened position at a price that is more favorable to the Client than the current price at the time of placing the Order. ',
      item44:
        'An "Open Position" – is a Short or Long Position that is not indicated as a full Completed Transaction. ',
      item45:
        '"Margin level" – the percentage of Required margin and Funds, which is calculated by the formula: (Funds / Required margin) × 100%. ',
      item46: '"Order level” – the price specified in the Order. ',
      item47: '"Precious metal" or "Spot Metal" – cash silver or cash gold. ',
      item48:
        '"Lot" – the standard Deal amount in the Trading Platform, expressed in the amount of Securities, Base Currency, troy ounces of Precious Metal. ',
      item49:
        '"Lot size" – means the number of Securities, units of Base Currency, Base Assets or troy ounces of Precious Metal specified in the Contract Specification in one Lot. ',
      item50:
        '"Affiliate" – means any legal entity that directly or indirectly controls the Company, or is itself controlled by it, or is under common control with the Company. "Control" here should be understood as the ownership of a controlling stake in the Company or the above-mentioned affiliates. ',
      item51:
        '"Log file of the client terminal” – a file that records accurate to the second all requests and orders sent by the Client to the Company. Created by the client terminal. ',
      item52:
        '"Server log file" – a file that records all requests and orders accurate to the second received from the Company\'s Client, as well as the results of their processing. Created by the server. ',
      item53:
        '"Locked Positions" – equal volume of Short and Long Positions opened by the Company on the same Instrument on the same Trading Account, including (but not limited to) any trading and / or non-trading operations. ',
      item54:
        '"Normal market conditions" – a market without rapid price movements, significant gaps in the Flow of Quotations on the Trading Platform and large price gaps. ',
      item55: '"Initial Margin” – the margin required to open a position. ',
      item56:
        '"Free Margin" – means such Funds on the Trading Account that can be used to open a position. Free margin is calculated by the formula: Funds – The required margin. ',
      item57:
        '"Hedged Margin" – is the margin required by the Company and described in the Contract Specification, which is sufficient to open and maintain Positions with the opposite direction. ',
      item58:
        '"Demand Price” or” Ask" – is the higher Quote price at which the Client can buy the currency. ',
      item59:
        '"Offer Price” or” Bid" – is the lower Quote price at which the Client can sell the currency. ',
      item60: '"Spread” – is the difference between Ask and Bid. ',
      item61:
        '"Price Gap" – A situation when the Current Ask Price is lower than the Bid Price at the previous Quote or when the Current Bid Price is higher than the Ask Price at the previous Quote. ',
      item62:
        '"Floating profit”," Floating loss" – current profits or losses on Open Positions, calculated at current Quotes. ',
      item63:
        'A "liquidity provider"– is a bank or ECN that provides streaming prices to a Company and can be used by it to hedge client deals. ',
      item64:
        '"Third-party service provider" – a legal entity agreed by the Parties, which will be obliged to perform operations on the corresponding position for both Parties. ',
      item65:
        '"Market conditions other than normal” – price gaps, low liquidity, rapid price changes in the market, and other conditions that differ from Normal market conditions. ',
      item66: '"Force majeure" - listed in Paragraph 16 of the Agreement. ',
      item67: "2. General regulations ",
      item68:
        "2.1 The Agreement is concluded between the Client and the Company. ",
      item69: "2.2 The Company should be understood BHP Trade . ",
      item70:
        '2.3 The Terms and Definitions used in the Agreement are specified in Paragraph 1 (hereinafter referred to as "Terms and Definitions"). ',
      item71:
        '2.4 The Agreement, together with the Regulatory Documents, represents the full volume of the agreements between the Client and the Company. The Regulatory documents include: Regulations for maintaining the Operations, the Regulations of Trading Operations, the Regulations for the provision of the PAMM Account Service, the Risk Disclosure Statement, as well as any other documents from the section "Treaties and Regulations" on the Company\'s Website, which may be amended or changed from time to time (hereinafter collectively referred to as "Regulatory Documents"). ',
      item72:
        "2.5 Regulatory documents are intended to regulate the procedure for performing all the Client's operations in the Company, both trading and non-trading, and should be carefully studied by the Client, since they contain important information that the Company is obliged to provide to the Client in accordance with the applicable provisions of the law. ",
      item73:
        "2.6 The Regulatory Documents, taking into account periodically made changes and additions, set out the conditions under which the Company is entitled to carry out deals with the Client regarding the Instruments. ",
      item74:
        "2.7 In relation to each Trading Operation of the Client, the Company acts not as its agent, but as a direct participant in the contract. Therefore, unless otherwise agreed, the Company will consider the Client as a Client in all respects, and the Client will be fully responsible for the fulfillment of obligations for any Trading Operation carried out by him. ",
      item75:
        "2.8 If the Client acts on behalf of or in relation to another person, regardless of whether the Client discloses his identity to the Company or not, the Company will not consider the above person an indirect client and will not bear any obligations to him (unless otherwise agreed). ",
      item76: "3. Entry into force ",
      item77:
        "3.1 This Agreement relates to individual Trading Operations or a series of consecutive Trading Operations, including operations with the Instruments, and is considered to be the original service agreement. ",
      item78:
        "3.2 The terms of the Agreement and Regulatory Documents are recognized as unconditionally accepted by the Client on the calendar day when the Client receives notification in accordance with Paragraph 4.1 and are valid until they are terminated at the initiative of either party. ",
      item79:
        "3.3 The Agreement enters into force remotely, being concluded in electronic form. The Client confirms that he does not have the right to cancel the Agreement only by reason that it is concluded remotely in electronic form. ",
      item80: "4. Services ",
      item81:
        "4.1 On condition of the fulfillment by the Client of all obligations arising in accordance with the Regulatory Documents and taking into account any other rights of the Company provided for in the provisions of the Regulatory Documents, the Company provides the Client with the following Services. ",
      item82:
        "4.1.1 Investment services, including receipt and transfer of applications, execution of Client's Orders for financial instruments at Client's own expense. ",
      item83:
        "4.1.2 Services with foreign currency that are related to the provision of Investment Services in accordance with Paragraph 4.1.1 of the Agreement. ",
      item84:
        "4.1.3 Responsible storage and control of financial instruments at the Client's expense (if applicable), including related services, for example cash management services described in Paragraph 8 of the Agreement; ",
      item85:
        "4.1.4 Providing the Client with access to any information about the analysis of its investments. ",
      item86:
        "4.1.5 Deposit insurance services. The client's account must be insured against the full or partial amount of losses (if the request for withdrawal of funds revealed a transaction on the client's account, causes risks for other users when using margin leverage, if the company notified about the provision of insurance regulations by mail to the client), according to the regulatory mechanism of the licensing authority the company. ",
      item87:
        "4.1.6 Services for converting funds when they are withdrawn (provided at the Client's expense). ",
      item88:
        "4.1.7 Services for the legalization of profit received by the Client in the form of assistance in opening an account with a foreign bank and registering an offshore company for the legalization of income. ",
      item89:
        "4.2 If the Client fulfills all the obligations listed in the Agreement and Regulatory Documents, the Company will provide the Client with the opportunity to perform the operations provided for in these documents using the Tools of the Website and the capabilities of the Personal cabinet. ",
      item90:
        '4.3 The Company carries out all Trading Operations with the Client on the principle of "execution only". The Company has the right to perform the above-mentioned Trading Operations, despite the fact that any Trading Operation may be unprofitable for the Client. ',
      item91:
        "4.4 The Company has the right to provide the Client, and the Client has the right to ask the Company for personal recommendations and investment advice in relation to any Trading Operations or opinions that could motivate the Client to carry out any Trading Operation. ",
      item92:
        "4.5 The Company may periodically, at its sole discretion, provide recommendations and other information in the news that it may publish on its Website, as well as in the form of newsletters to subscribers through its Website or otherwise. Any recommendations, analytical reports and other information can be published by the Company on one or more interfaces of information services. In this case, the following rules apply. ",
      item93:
        "4.5.1 The above information does not an investment recommendation or promotion of a financial product not requested by the Client and is provided solely to enable the Client to make its own investment decisions. ",
      item94:
        "4.5.2 The Company does not provide the Client with any assurances or guarantees regarding the tax consequences of any Trading Operation, as well as the completeness and accuracy of the above information. ",
      item95:
        "4.5.3 The Company does not make any statements regarding the period of receipt of information by the Client. In this regard, the Company does not guarantee that the Client will receive the above information at the same time as its other clients. ",
      item96:
        "4.5.4 Any information provided by the Company on the website may be changed or deleted at any time without notice, including market comments, news. ",
      item97:
        "4.5.5 If any document of the Company contains any restriction regarding the person or category of persons for whom this document is not intended, the Client is obliged not to transfer this document to any of the above-mentioned person or category of persons. ",
      item98:
        "4.5.6 The information does not necessarily take into account the relevant legal or regulatory provisions of the country of residence of the Client. It follows that the Client is obliged to comply with the above provisions independently. ",
      item99:
        "4.6 The Company is not obliged (unless otherwise specified in the Regulatory Documents) to monitor or notify the Client about the status of any Trading Operation of the Client, about the closing of any Open Positions, about the presentation of margin requirements. ",
      item100:
        "4.7 The Company is not obliged to provide physical delivery of the Underlying Asset of any Instrument in relation to any Trading Operation. Profits or losses from foreign exchange differences will be reflected immediately after the closing of the Trading Operation on the Trading Account. ",
      item101:
        "4.8 The Client agrees that the Company has the right to request, and the Client is obliged to provide information about the knowledge and experience in the field of investment. The Client also confirms that this information will be provided in such a way that the Company can evaluate whether the service or product in question is suitable for the Client. If the Client does not provide the Company with the above information in sufficient volume, the Company will not be able to determine whether the service or product in question is suitable for the Client. The Company will consider that the above information provided by the Client is accurate and will not be responsible to the Client in case of incompleteness, unreliability, changes in the information (except in cases when the Client notifies the Company of these changes) or if it is misleading. ",
      item102:
        "4.9 The Company has the right, at its sole discretion, to refuse to provide Services to the Client at any time and without informing about the reasons. In case of occurrence of Market conditions other than normal, the Company has the right to suspend, delay, change the terms of providing of its services. ",
      item103:
        "4.10 The Company has the right to close, suspend or cancel any Trading Operation that has arisen as a result of any incorrect configuration or technical error of the Server, as well as if it suspects any fraudulent activity on the Client's account (s). Such activities may include (but are not limited to): arbitrage deals of purchase and sale, manipulative actions or other forms of misleading activity, fraudulent activity, otherwise associated to or related to any or all of the Client's Trading Operations. In this case, the Company will have the right to deduct any income that it considers to be improperly received and charge related costs. Also in this case, the Company will not be responsible for the cancellation of any Trading Operation or the Client's income, will not be responsible in the event of any damage or loss that may arise as a result of the closure, suspension or cancellation of the Trading Operation. ",
      item104:
        "4.11 All Requests from the Client are considered in terms of their size. The Order may be executed partially or may be rejected entirely at the sole discretion of the Company, if the size of the Request from the Client is greater than the Company can execute at a certain time in accordance with current market conditions. ",
      item105:
        "4.12 The Client is obliged to provide at the request of the Company personal data and documents about himself as a Client: full name, date and place of birth, address, jurisdiction of residence / registration, bank account number. The Client agrees to transfer the above information to the Company registered as a data controller in accordance with the Law, necessary for identification, administrative and business purposes in order to fulfill its legal and contractual obligations in accordance with this and other agreements of the parties. The above information is provided to the Company with the right to transfer it to lawyers, auditors, financial consultants and other service providers and contractors with whom the Company enters into contracts. ",
      item106:
        "4.13 The Client confirms that the trading and non-trading history stored in his Personal Cabinet will be available for viewing and downloading at any time. ",
      item107:
        "4.14 If the client has become the subject of financial monitoring (blocking the wallet over the network), funds in the client’s account go through the process of withdrawal through respondent banks serving cryptographic smart contracts, converting client funds into the client’s national currency after providing the data of the recipient’s bank to the company. ",
      item108: "5. Activation of Personal Cabinet and Trading Account ",
      item109:
        "5.1 The Company will activate the Client's Personal Cabinet and notify the Client about it after receiving the completed Client Registration Form from the Client, conducting appropriate checks of identification documents in accordance with the Company's requirements, approving the methods of depositing and withdrawing funds by the Client, as well as after the Client accepts the terms of Regulatory Documents. ",
      item110:
        "5.2 The Company has the right, at its sole discretion, to refuse to register the Client's Personal Account on the basis of the documentation received from the Client, even if the Client has completed it properly and in full. ",
      item111:
        "5.3 The Company has the right to demand the Client to make a minimum down payment to their Trading Account in order for the Client to start using it. ",
      item112:
        "5.4 After activation of the Personal Cabinet and Trading Account, the Client will be able to see the amount due to him in the form of the account balance in the Personal Cabinet at any time and will be entitled to withdraw it upon request. ",
      item113: "6. Exchange of information ",
      item114:
        "6.1 The rules for the exchange of information between the Company and the Client are set out in the Regulations of Trading Operations, which are posted on the Company's Website in the section “Treaties and Regulations”. ",
      item115:
        "6.2 The Client is obliged to provide Orders and Requests exclusively through the Client Terminal in accordance with the above-mentioned Regulations of Trading Operations. ",
      item116: "7. Limitation of Responsibility and compensation of losses ",
      item117:
        "7.1 The Company is not responsible to the Client for the use of any information, advice or recommendations provided. The Client acknowledges that the Company will not be responsible for any losses, expenses, charges or damages suffered by the Client as a result of any inaccuracy or mistake in any information provided to the Client, that related to any Trading Operations, except in the case of malicious acts, fraud or gross negligence on the part of the Company. Subject to the Company's right to close or cancel any Trading Operation in certain circumstances specified in the Regulatory Documents, after the above mistake or inaccuracy, this Trading Operation, however, remains valid and imposes obligations in all respects on both the Client and the Company. ",
      item118:
        "7.2 The Company will not be responsible for any losses or expenses suffered by the Client and directly or indirectly arising in connection with: actions, omissions or carelessness of any third party; any violation of the Company's obligations specified in the Regulatory Documents that occurred for reasons beyond the Company's control; trading Operations conducted through the Client Terminal; any failure in the Trading Platform, or any error in the Trading Platform, or any delay caused by the Client Terminal. ",
      item119:
        "7.3 At the request of the Company, the Client is obliged to compensate the Company's expenses of any kind (including costs, obligations, claim requirements) that the Company assumes or bears as a direct or indirect result of any failure by the Client to fulfill any obligations assumed by him in accordance with Regulatory Documents. ",
      item120:
        "7.4 Unless otherwise specified in Regulations of Trading Operations, the Company will not under any circumstances be responsible to the Client for any direct or indirect losses, lost profits or benefits (including in relation to consecutive market movements), for costs, expenses or damages that the Client may incur in accordance with Regulatory Documents. ",
      item121: "8. Commissions and other costs ",
      item122:
        "8.1 The Client is obliged to pay the Company commissions and bear other costs, which are specified in the Contract Specification, located on the Company's Website in the “Trading Conditions” section. ",
      item123:
        "8.2 The Company is obliged to publish all applicable commissions and other costs on its Website and has the right to make changes to this information without providing the Client with prior Written Notice. The fact that the above-mentioned changes in commissions and other costs are published on the Company's Website will be considered a proper notification of these changes. ",
      item124:
        "8.3 The Company has the right to notify the Client, and the Client agrees to receive the above notifications when paying any commissions / fees to any third party who has represented the Client to the Company or who acts on his behalf. ",
      item125:
        "8.4 The Client is obliged to pay all stamp duties necessary for the implementation of Trading Operations related to the Agreement and Regulatory Documents. ",
      item126:
        "8.5 The Client is personally responsible for all submitted reports and tax declarations on any Trading Operations that must be submitted to any relevant state or other authority and for the payment of all taxes that are imposed on any Trading Operation, including, without limitation, the transfer of securities or property tax or value added tax. ",
      item127:
        "8.6 All Client's funds are stored in the Company's accounts, including segregated accounts opened in the Company's name for storing Client funds separately from Company's funds. ",
      item128:
        "8.7 The Company does not charge a commission for opening an account and maintaining the account. ",
      item129:
        "8.8 The company does not charge a commission for depositing and withdrawing funds; the commission can be charged by the payment systems used by the client. ",
      item130: "9. Use of the Trading Platform and security ",
      item131:
        "9.1 The Client may not commit any actions or omissions during the use of the Trading Platform, which may result in a violation of the security of the Platform or its improper work. The Client does not have the right to allow any actions or omissions that could potentially lead to inappropriate use of the Trading Platform or unauthorized access to it. The Client acknowledges that the Company has the right, at its sole discretion, to terminate or restrict the right of access to the Trading Platform if suspects the Client in inappropriate use of it or has begun to carry out the above actions. ",
      item132:
        "9.2 The Client has the right to store, analyze, modify, display, format and print information provided on the Company's Trading Platform. However, the Client may not publish, reproduce or otherwise transfer in whole or in part the information provided on the Company's Trading Platform to any third party in any format without the consent of the Company. The Client does not have the right to change, delete or hide any references to trademarks, as well as copyright or any other rights presented on the Company's Trading Platform. ",
      item133:
        "9.3 The Client is obliged to keep in secret and not disclose any Data of access to the Company's Trading Platform to any person. If the Client suspects or becomes aware that his Access Data has been or may have been disclosed to any unauthorized person, the Client is obliged to immediately notify the Company. ",
      item134:
        "9.4 The Client acknowledges that the Company will not be responsible if any unauthorized persons gain access to information (including personal data, email addresses, electronic correspondence) when this information is transferred via the Internet or other network communication means, as well as by mail, telephone or other electronic means. ",
      item135:
        "9.5 The Client is responsible for all orders or instructions transferred and provided on the basis of his Access Data. Any of the above orders or instructions received by the Company will be deemed to have been received by it from the Client. ",
      item136:
        "9.6 The Client is obliged to assist the Company in any investigative activities that the Company may carry out in connection with an attempt to unauthorized access and improper use of its Access Data. ",
      item137:
        "9.7 If the Company suspects the commission of fraud, manipulation, swap arbitration or other form of misleading activity on the Client's account (s) with the Company or unauthorized activities otherwise related to any Trading Operations, it has the right to: ",
      item138:
        "1) close all opened positions on the Client's Trading Account; ",
      item139:
        "3) cancel all income received as a result of the above actions; ",
      item140: "10. Modification and termination of the Agreement ",
      item141:
        "10.1 The Company has the right, at its sole discretion, unilaterally and at any time to make changes to Regulatory Documents. In this case, the Company is obliged to notify the Client of the above changes by publishing information about them on its Website or by providing the Client with a notification by e-mail. ",
      item142:
        "10.2 Both parties have the right to terminate the Agreement by providing each other with Written Notices to this effect. ",
      item143:
        "10.3 Upon termination of the Agreement, the Company has the right to stop providing the Client with access to the Trading Platform without prior notice. ",
      item144:
        "10.4 Upon termination of the Agreement, all amounts that the Client is obliged to pay to the Company will be subject to immediate payment, including (but not limited to): ",
      item145: "1) all commission fees and charges payable; ",
      item146:
        "2) any operating expenses incurred by the Company in connection with the termination of the Agreement, as well as expenses incurred by it when transferring the Client's investment to another investment company; ",
      item147:
        "3) any losses and expenses incurred by the Company in closing any Trading Operations of the Client and in settling or fulfilling any overdue obligations assumed by the Company on behalf of the Client. ",
      item148:
        "10.5 The Company may, at its sole discretion, deactivate the Client's account without prior notice if the Client places such a number of erroneous requests that deviate from the standard and which create an excessive load on the Company's Servers, as a result of which they may lead to a negative trading experience of other clients using these Servers. ",
      item149:
        "Erroneous requests may include, but are not limited to, a large number of requests or orders with insufficient funds in the Client's account, incorrect processing of Trading Operations or the system log, as well as any not valid steps or changes. ",
      item150: "11. Conflict of interests and material interest ",
      item151:
        "11.1 In cases where the Company carries out deals with or on behalf of the Client, the Company, a related person or any other person affiliated with the Company may have an interest or arrangements that may be material in relation to the Trading Operation in question or that may be contrary to the interests of the Client. Below are examples of the Company's actions in cases where it carries out any deals with or on behalf of the Client. ",
      item152:
        "11.1.1 The Company may carry out deals with the relevant Instrument as a principal at its own expense by purchasing the Instrument from the Client or selling the Instrument to the Client. ",
      item153:
        "11.1.2 The Company may carry out deals with the Instrument that it offers to the Client, including holding a Short or Long Position. ",
      item154:
        "11.1.3 The Company may coordinate Trading Operations with the Client or with another client, acting on behalf of the Client or on behalf of the above-mentioned other client. ",
      item155:
        "11.1.4 The Company may provide advice and other services to any related parties or other clients of the Company who may have an interest in the Underlying Assets or Investments, provided that this interest may conflict with the interests of the Client. ",
      item156:
        "11.2 The Client authorizes the Company to carry out deals with the Client or on its own behalf in any manner that the Company will consider appropriate, despite any conflict of interests or the existence of any material interest of the parties in the Trading Operation. The Client agrees that the Company is not obliged to notify him in advance of the above deals. ",
      item157:
        "11.3 The Company's employees are obliged to comply with the independence policy and not to take into account any conflict of interests or material interest of the parties when providing advice to the Client. ",
      item158: "12. Personal data and phone call recording ",
      item159:
        "12.1 The Company has the right to use, store and process the Client's personal data that he provided to the Company in connection with the providing of Services. ",
      item160:
        "12.2 If the Client is a physical entity, the Company is obliged to provide a copy of the document with the Client's personal data at the request of the Client, provided that the Client has made a payment for this service and provided the availability of a copy at the Company's disposal. ",
      item161:
        "12.3 The Client agrees that the Company has the right to transfer his data to any third parties for the effective provision of Services or for the effective execution of any operational function carried out in favor of the Client (for example, compensation of the Client's funds). ",
      item162:
        "12.4 The Client agrees that any Telephone conversations between him and the Company may be recorded and that these recordings will remain the exclusive property of the Company and will be accepted by the Client as final proof of Orders / Requests or telephone conversations. The Client agrees that the Company has the right to provide copies of the transcripts of the above records to any regulatory or state authority, any court. ",
      item163: "13. Consent to direct contact ",
      item164:
        "13.1 The Client agrees that the Company has the right to periodically contact the Client directly by phone or using other means of communication for the purpose of marketing its financial services and products. ",
      item165: "14. Confidentiality and Refusal ",
      item166:
        "14.1 Any information about the Client which the Company possesses is confidential and cannot be used for any purpose other than the provision of Services by the Company to the Client. Information of a confidential nature should be treated in accordance with the Law, provided that it is not owned by the Company and was not subject to a confidentiality or non-disclosure obligation at the time it was received by the Company, and also provided that it is not publicly available. ",
      item167:
        "Confidential Information may be disclosed by the Company to any person only in the following cases: ",
      item168: "14.1.1 In accordance with the requirements of the Law. ",
      item169:
        "14.1.2 In order to prevent or investigate any illegal activity, including fraud. ",
      item170:
        "14.1.3 When confidential information is required: for purposes related to the provision of Services, for the administration of the Client's Trading Account, for the purpose of requesting, for the purpose of evaluating the loan amount, for identification purposes. ",
      item171:
        "14.1.4 When necessary confidential information to any third party in connection with the providing Services to Client and (or) employees of the Company in order to perform their duties in accordance with Regulatory documents and (or) lawyers, consultants, auditors of the Company, provided that in each case the appropriate specialist will be notified of the confidential nature of the above information about the Client and took the above secrecy obligation transferred him confidential information about the Client. ",
      item172:
        "14.1.5 When confidential information is requested by the Client or other persons with the written consent of the Client. ",
      item173:
        "14.1.6 When confidential information is required in any court proceedings or in any public dispute between the Client and the Company. ",
      item174:
        "14.1.7 When confidential information is required at the request of the courts, law enforcements, regulatory and similar bodies in the jurisdiction of which the Company is located. ",
      item175: "15. Refusal to satisfy the claim ",
      item176:
        "15.1 The Company has the right to refuse to satisfy the Client's claim. ",
      item177:
        "15.2 Claims made by the Client in respect of any Orders not fulfilled by the Company, given during the scheduled maintenance work on the Server, will not be accepted if the Company has notified the Client in advance of the above work in any way. If the Client has not received the above notification, this fact does not serve as a basis for making a claim against the Company. ",
      item178:
        "15.3 Also, claims based on the difference between the price of the underlying asset of the Contract for Difference and the price of execution of the Order under the Contract for Difference will not be accepted for consideration. ",
      item179:
        "15.4 No claims are accepted for the time of Order execution, regardless of the time interval that the Company took to execute the Order, and regardless of the time when the Order execution record appeared in the Server Log File. Claims are accepted only in cases where the execution of the Order placed in the queue was not carried out in accordance with the Rules of Trading Operations. ",
      item180:
        "15.5 Claims for cancellation of financial results of Trading Operations performed by the Client for additional margin that was formed on the Trading Account as a result of a profitable deal at a non-market quote or at a quote received by the Client as a result of an obvious mistake of the Company and subsequently canceled are not accepted. ",
      item181:
        "15.6 The Client acknowledges that he will not be able to control all Open Positions or give Orders to open new positions during the period of time when the Disputed Situation in relation to the contested position is under consideration by the Company, and that no claims on this issue will be accepted. ",
      item182:
        "15.7 The Company has the right to cancel any Trading Operation if the relevant Hedging Trade has been canceled by the Liquidity Provider. The Company has the right not to accept any claims under this circumstance. ",
      item183: "16. Force majeure ",
      item184:
        "16.1 The Company has the right to determine, at its reasonable discretion, whether any Force Majeure Event has occurred and, if such a circumstance exists, is obliged to take reasonable measures to properly notify the Client of this. Force majeure circumstances include, but are not limited to, the following cases. ",
      item185:
        "16.1.1 Any actions and events that, at the Company's reasonable discretion, do not allow it to properly maintain market conditions for any Instruments, including (but not limited to): terrorist acts, wars, mass and civil unrest, strikes, refusal of the employer to provide work for its subordinates, actions of state bodies; force majeure, natural disasters, fires, floods, storms, accidents; supplier failures, power failures, electronic or communication equipment failures. ",
      item186:
        "16.1.2 Non-occurrence or cancellation of any event on which the Company's Quotes depend; closure, suspension or liquidation of any market; imposition of restrictions or establishment of special, non-standard trading conditions for any of the above events, in any of the above markets. ",
      item187:
        "16.2 If the Company reasonably believes that there is a Force Majeure event, it may at any time, without prior Written Notice to the Client, but also without prejudice to any other rights arising in accordance with the Regulatory Documents, take any or all of the following measures: ",
      item188: "a) increase margin requirements; ",
      item189:
        "b) close Open Positions at prices that the Company reasonably considers appropriate; ",
      item190:
        "c) change, suspend or freeze the application of any (or all) terms of Regulatory Documents to the extent that Force Majeure circumstances prevent their compliance, make it impossible or impractical for the Company to comply with them; ",
      item191:
        "d) take any other measures (refrain from taking measures) that the Company reasonably considers appropriate in force majeure circumstances in relation to the situation of the Company, the Client and other clients. ",
      item192: "17. Nonfulfillment of obligations ",
      item193: "17.1 “Cases of default” include the events listed below. ",
      item194:
        "17.1.1 Failure of the Client to fulfill any or more obligations to the Company specified in the Regulatory Documents. ",
      item195:
        "17.1.2 Failure of the Client to submit any amount payable in accordance with Regulatory Documents. ",
      item196:
        "17.1.3 If the Client is a physical entity, the initiation of court proceedings by any third party concerning its bankruptcy; if the Customer is a legal entity, the initiation of court proceedings by any third party concerning its liquidation or for the appointment of the administrator or the bankruptcy Trustee in relation to the Client and (or) any Client's assets.; if the Client, being a physical or legal entity, agrees with its creditors or enters into a settlement agreement with them, as well as if any procedural actions are initiated against the Client that are similar to any of the above actions and processes. ",
      item197:
        "17.1.4 If, in accordance with Paragraph 15 of the Agreement, any statement made by the Client or any guarantee provided is or becomes false. ",
      item198:
        "17.1.5 If the Client is unable to repay his / her debts to the Company at the maturity date. ",
      item199:
        "17.1.6 If the Client, being a physical entity, becomes incapacitated due to a mental disorder or dies. ",
      item200:
        "17.1.7 In the event of any circumstances where the Company reasonably believes that the preferred solution will be to take any measures specified in Paragraph 18.5 of this Agreement. ",
      item201:
        "17.1.8 If the Client makes any attempts to engage in actions defined by the Company as manipulative, fraud, swap arbitration or other forms of activity that mislead the Client's account (s) opened with the Company. ",
      item202:
        "17.1.9 If the Client conducted a trade that can be described as excessive in order to benefit from price changes in the market without legitimate intentions and (or) which can be classified as market fraud and (or) which was conducted through arbitration and (or) based on price lag. ",
      item203:
        "17.2 The Company, in the event of any of the above-mentioned Cases of Nonfulfillment of Obligations, has the right to take one or more of the following measures at any time and without prior Written notice to the Client. ",
      item204:
        "17.2.1 Close any part (or all) Open positions of the Client at current Quotes and (or) Trading accounts of the Client in the Company. ",
      item205:
        "17.2.2 Take off from the Client's Trading Account the amounts due to the Company in accordance with the Agreement and Regulatory Documents. ",
      item206: "17.2.3 Refuse to open a new Trading Account for the Client. ",
    },
  },

  refund: {
    title: "Refund & Cancellation Policy",
    item1:
      "1. The Client has the right to deposit their trading account only using the payment systems available on Сompany website.",
    item2:
      "2. The actual payment of services is deemed to be the moment when all appropriate funds are credited to the Company's account.",
    item3:
      "3. The Company holds no responsibility for the result of trading operations on the Client's account, in case there are any delays in depositing this account. The Client is solely responsible for any financial loss risks coming from possible delays in depositing funds to the Client's trading account.",
    item4:
      "4. The Company has the right to cancel the Client's deposit or withdrawal if the Client's trading account is going to being deposited by a third party or Client is going to withdraw on the name of third party. In this case the deposited funds will be transferred back to the same bank details they came from. If this occurs, the Client is charged with all costs for funds transfer.",
    item5:
      "5. In the event the trading account is credited in any currency other than the one in which the trading account is maintained, such funds are to be credited to the trading account on the basis of an internal exchange rate adopted by the Company from the Bank serving the Company at the date of deposit.",
    item6:
      "6. When processing a withdrawal request, the Company shall use its internal currency rates adopted by the Company from the Bank serving the Company at the date of withdrawal.",
    item7:
      "7. If the trading account cannot be credited automatically, the appropriate application will be fulfilled within 24 hours since the client's notice on crediting the trading account is received.",
    item8:
      "8. In case the Client deposits their trading account by means of direct transfer to the Company's bank details, they are obliged to leave a deposit notification in «Live Account».",
    item9:
      "9. The Client can remove its funds into any payment systems designated in its Live Account under the caption «Withdrawals».",
    item10:
      "10. When withdrawing funds from the trading account, the Client is charged with all costs for funds transfer.",
    item11:
      "11. The funds can be withdrawn only into such payment system that was used for crediting the trading account and regarding to the sufficient trade volume. The quantum of a sufficient trade volume is established by the Company at its own discretion.",
    item12:
      "12. The Client bears full responsibility for the adequacy of all the information set out in its application for the removal of funds.",
    item13:
      "13. The Company has the right to refuse the Client's request for withdrawal, if the Client uses the same payment system for withdrawal and depositing, but with different bank details.",
    item14:
      "14. The removal of funds from the Client's account is accomplished within 2 business days from the time a relevant application for the removal of such funds has been confirmed in the Live Account.",
    item15:
      "15. The Company reserves the right to deduct from the account an amount paid to the Client in compensation, if the account does not embrace a sufficient trade volume or the account is used for the purpose of converting funds from one payment system into another. The quantum of a sufficient trade volume is established by the Company at its own discretion.",
    item16:
      "16. The funds credited through Credit Card can be removed only upon completion of a legality check by the operator, not any earlier than within 30 days after the crediting of such funds.",
    item17:
      "17. Funds deposited by means of Credit Cards (Bank Cards), as well as profit earned during trading, can be withdrawn only after they are checked for legality and no sooner than 15 days after the last deposit. With UnionPay withdrawal is available immediately after documents verification process is completed.",
    item18:
      "18. In case the payment system stops operating for some time, the Company has the right to postpone the date of withdrawal until the payment system resumes working.",
    item19:
      "19. The Company has the right to charge the Client with an additional commission if the Client uses different payment systems to deposit and withdraw funds. The amount of the commission charged to the Client is calculated based on costs paid by the Company when fulfilling the Client's withdrawal request.",
    item20:
      "20. In order to remove the funds through exchange mechanisms (with the employment of cash at an exchange office), it is necessary to fill in an application indicating adequate data about the recipient of such funds: full name, surname, passport details.",
    item21:
      "21. The Company undertakes to take any and all expedient actions to preclude any illegal financial operations and money laundering with the employment of the Company's resources.",
  },

  risk: {
    title: "Risk Disclosure Notice",
    item1:
      "CFD’s can put your capital at risk if used in a speculative manner. CFD’s are categorized as high risk by some regulatory authorities as there is no protection of capital, no guaranteed return and customers can lose the amount invested. With the BHP Trade Trading Platform it is not possible to lose more than the amount invested and customers cannot be left in debt to BHP Trade. Trading CFD’s is not suitable for all investors; make sure you fully understand the risks involved.",
    item2:
      "This Risk Disclosure Notice forms part of the Client Agreements as defined in the User Agreement",
    item3:
      '1.1. You are considering dealing using the BHP Trade ("we", "our", "us") trading platform in Contracts for Difference (\'CFDs\'). CFDs are high risk investments, which are not suitable for many investors.',
    item4:
      "1.2. This notice provides you with information about the risks associated with CFDs, but it cannot explain all of the risks nor how such risks relate to your personal circumstances. If you are in doubt you should seek professional advice.",
    item5:
      "1.3. It is important that you fully understand the risks involved before deciding to enter into a trading relationship with us. If you choose to enter into a trading relationship with us, it is important that you remain aware of the risks involved, that you have adequate financial resources to bear such risks and that you monitor your positions carefully.",
    title2: "2. MAGNIFIED LOSSES",
    desc2:
      "2.1. The nature of margin trading markets means that both profits and losses can be magnified and, unless you place a Close at Loss or a Limit Order, you could incur very large losses if your position moves against you. However you cannot lose more than the balance on your Trading Account.",
    title3: "3. CFDS ARE NOT SUITED FOR LONG TERM INVESTMENT",
    desc3:
      "3.1. CFDs are not suited to the long term investor. If you hold a CFD open over a long period of time the associated costs increase, and it may be more beneficial to buy the underlying asset instead.",
    title4: "4. NO RIGHTS TO THE UNDERLYING INSTRUMENT",
    desc4:
      "4.1. CFDs do not provide any right to the underlying instruments, or in the case of CFDs referenced to shares, to voting rights.",
    title5: "5. SUITABILITY",
    desc5:
      "5.1. Subject to our obligation to assess how appropriate the Trading Platform is to your circumstance or any decision whether or not to open an account and the risks involved is completely yours.",
    desc6:
      "5.2. We may also ask you for information about your financial assets and earnings. We do not monitor on your behalf whether the amount of money that you have sent us or your profits and losses are consistent with that information. It is up to you to assess whether your financial resources are adequate and what level of risk you take.",
    title6: "6. POTENTIAL RISKS",
    desc7: "6.1. You cannot lose more than your Trading Account balance.",
    desc8:
      "6.2. Before you open a CFD trade with us we require you to lodge money with us as Initial Margin and, in order to keep a Transaction open, you must ensure that the amount in your Trading Account exceeds the Maintenance Margin. The Initial Margin will differ between Instruments and the amounts will be indicated on the Trading Platform. This means that you will be trading using 'leverage' or 'gearing' and this can work for or against you; a small price movement in your favour can result in a high return on the Initial Margin placed for the trade, but a small price movement against you may result in substantial losses.",
    desc9:
      "6.3. We will further require you to ensure that the amount in your Trading Account exceeds the Maintenance Margin in order to keep a Transaction open. Therefore, if our price moves against you, you may need to provide us with substantial additional Margin, at short notice, to maintain your open trades. If you do not do this, we will be entitled to close one or more or all of your trades. You will be responsible for any losses incurred.",
    desc10:
      "6.4. You should also be aware that under our User Agreement we are entitled, at our sole discretion, to make a Margin Call. Under the User Agreement, you are required to satisfy any Margin Calls immediately, by wire transfer or bank card transfer in the time prescribed by us. If you do not do this, we will be entitled to close one, or more, or all of your trades.",
    desc11:
      "6.5. Unless you have taken steps to place an absolute limit on your losses (for example, by placing a Close at Loss or Close at Profit order on your account) it is possible for adverse market movements to result in the loss of the entire balance of your Trading Account. We offer a range of risk management tools to help you to manage this risk.",
    title7: "7. NOT SUITABLE AS INCOME",
    desc12:
      "7.1. The inherent concept of CFDs means they are not suitable for an investor seeking an income from his investments as the income from such investments may fluctuate in value in money terms. For an investment in an OTC product, which is not a readily realisable investment, it may be difficult to sell or realise the investment and obtain reliable information about its value or the extent of the risks to which it is exposed.",
    title8: "8. FLUCTUATIONS IN THE MARKET",
    desc13:
      "8.1. It is important that you comprehend the risks associated with trading on a market as fluctuations in the price of the underlying market will have an effect on the profitability of the trade. For example: the value of investments denominated in foreign currencies may diminish or increase due to changes in the rates of exchange.",
    desc14:
      "8.2. CFDs are therefore only suitable for those customers who fully understand the market risk and have previous trading experience. If unsure, it is advisable to seek independent advice.",
    title9: "9. DERIVATIVES",
    desc15:
      "9.1. Positions opened with us are not traded on any exchange. The prices and other conditions are set by us, subject to any obligations we have to provide best execution, to act reasonably and in accordance with our user agreement and with our order execution policy. Each CFD trade that you open through our Trading Platform results in you entering into a contract with us; these contracts can only be closed with us and are not transferrable to any other person.",
    title10: "10.NEED TO MONITOR POSITIONS",
    desc16:
      "10.1. Because of the effect of gearing and therefore the speed at which profits or losses can be incurred it is important that you monitor your positions closely. It is your responsibility to monitor your trades.",
    title11: "11.OPERATIONAL RISKS",
    desc17:
      "11.1. Operational risks with BHP Trade on your computer are inherent in every CFD transaction. For example, disruptions in operational processes such as communications, computers, computer or mobile networks or external events may lead to delays in the execution and settlement of a transaction. BHP Trade does not accept or bear any liability whatsoever in relation to the operational processes of BHP Trade, except to the extent that it is caused by the fraud or dishonesty by BHP Trade.",
    title12: "12.CURRENCY RISK",
    desc18:
      "Customers should be aware that CFD's denominated in currencies other than their home currency have the additional risk associated with currency fluctuations.",
  },

  conflict: {
    title: "Conflict of Interest Policy Statement",
    item1:
      "This Conflict of Interest Policy Statement outlines how BHP Trade manages the conflicts which can arise within BHP Trade, between BHP Trade and its customers and between customers of BHP Trade. This Policy is reviewed from time to time to take account of changes to operations or practices and, further, to make sure it remains appropriate to any changes in law, technology and the general business environment. This policy applies in respect of all activities undertaken by BHP Trade and employees of BHP Trade.",
    item2:
      "1. BHP Trade undertakes all trading as principal to it’s customers transactions, it hedges its net risk with counterparties which means that customers aims are more aligned with its own aims notwithstanding it is acting as principal in all transactions. As BHP Trade does not provide investment advice no conflict can arise from this source. BHP Trade only offers CFD's, therefore any conflict which could arise between different product lines cannot exist.",
    item3:
      "2. BHP Trade takes responsibility for identifying and managing other conflicts of interest arising in its business that may entail a material risk of damage to the interests of customers. BHP Trade have considered, in particular, the potential conflicts of interest arising out of the execution of transactions for its customers.",
    item4:
      "3. The following measures have been adopted in relation to other conflicts of interest:",
    item5:
      "(a) When BHP Trade executes an order for a customer it does not give any preferential treatment to that customer to the detriment of other customers and does not disclose the details of one customer order to other customers.",
    item6:
      "(b) BHP Trade executes all orders electronically, in accordance with policies that comply with relevant rules and regulations in relation to transactions which are executed for or on behalf of customers. BHP Trade's procedures provide for the prompt, fair and expeditious execution of customer orders, relative to other orders. These procedures allow for the execution of otherwise comparable orders in accordance with the time of their receipt.",
    item7:
      "(c) BHP Trade's procedures are established to ensure that orders executed on behalf of customers are promptly and accurately recorded and that it carries out otherwise comparable orders sequentially and promptly unless the characteristics of the order or prevailing market conditions make this impractical or the interest of the customer requires otherwise.",
    item8:
      "(d) BHP Trade's procedures ensure that BHP Trade, its employees and affiliates cannot misuse information relating to pending customer orders.",
    item9:
      "(e) All employees of BHP Trade are prohibited from dealing on personal accounts to avoid any actual or perceived conflicts of interest.",
    item10:
      "(f) All employees of BHP Trade are prohibited from accepting gifts or other inducements from any person with any material interest which is likely to conflict to a material extent with any duty which BHP Trade and/or its employees owe in connection with its treating customers fairly policy or any duty which such a recipient owes to customers.",
    item11:
      "4. BHP Trade is committed to having an effective and appropriate compliance culture to enable it to deal with any new potential conflicts of interest which may arise in the future. BHP Trade's employees are therefore required to monitor any new circumstances giving rise to potential conflicts and to implement appropriate measures to address these.",
    item12:
      "5. In the unlikely event that BHP Trade's arrangements to manage conflicts of interest are not sufficient to ensure, with reasonable confidence, that risks of damage to the interests of a customer will be prevented, BHP Trade are required to clearly disclose the general nature and/or sources of conflicts of interest to a customer before undertaking business for them.",
    title2: "When and how to contact us",
    item13:
      "If you have any queries about this conflict of interest policy, or you wish to help us improve this Policy, please notify us by contacting us at: info@bhp-trade.com",
  },
};
