import React from "react";
import Banner from "../components/Home/Banner";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Features from "../components/Home/Features";
import CryptoList from "../components/Home/CriptoList";
import Token from "../components/Home/Token";
import Partners from "../components/Home/Partners";
import License from "../components/Home/License";

const Main = () => {
  return (
    <div className="main">
      <NavBar />
      <Banner />
      <Features />
      <CryptoList />
      <Token />
      <Partners />
      <License />
      <Footer />
    </div>
  );
};

export default Main;
